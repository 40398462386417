export class Contacto {
    constructor(
        public id: string,
        public nombre: string,
        public apellido: string,
        public nombre_completo: string,
        public tipo_documento: string,
        public numero_documento: string,
        public puesto_trabajo: string,
        public descripcion: string,
        public direccion: string,
        public ciudad: string,
        public departamento: string,
        public pais: string,
        public celular: string,
        public telefono: string,
        public email: string,
        public fecha_creacion: Date,
        public ultima_modificacion: Date
    ) {}
}