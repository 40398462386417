import { Component, Input, OnInit } from "@angular/core";
import { ModalController, ToastController } from "@ionic/angular";
//Utilizar geolocation de Capacitor
import { IonicSelectableComponent } from "ionic-selectable";
import { Subscription } from "rxjs";
import * as moment from "moment";

import { CrmService } from "src/app/api/crm.service";
import { TokenService } from "../../login/token.service";
import { Cliente } from "../../models/cliente.model";

import "moment/locale/es";
import { Negocio } from "src/app/models/negocio.model";

import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { NegociosPorEtapa } from "src/app/models/negocios-etapa.model";
import { format, parseISO } from "date-fns";
import { Contacto } from "src/app/models/contacto.model";

@Component({
  selector: "app-modal-detalle-reunion-calendario",
  templateUrl: "./modal-detalle-negocio-tabnegocios.component.html",
  styleUrls: ["./modal-detalle-negocio-tabnegocios.component.scss"],
})
export class ModalDetalleNegocioTabnegociosComponent implements OnInit {
  @Input() idCliente: string;
  @Input() clientes: Cliente[]= [];
  @Input() contactos: Contacto[];
  @Input() negocio: Negocio;
  @Input() negocios: Negocio[];
  @Input() negociosPorEtapas: NegociosPorEtapa[];
  @Input() accion: string;
  @Input() auxNegocio: Negocio;

  public subscription: Subscription;
  public tiposNegocio = JSON.parse(this.tokenService.getParametros())[
    "Opportunities"
  ]["tipos"];
  public tomasContacto = JSON.parse(this.tokenService.getParametros())[
    "Opportunities"
  ]["tomasContacto"];
  public etapasVenta = JSON.parse(this.tokenService.getParametros())[
    "Opportunities"
  ]["etapasVenta"];
  public pagConsultaCliente: number = 1;
  public cantPaginasCliente: number;
  public fechaCierreString: string;
  public contactosSeleccionados;

  validarNegocio = this.fb.group({
    nombre: new FormControl("", [Validators.required, Validators.minLength(3)]),
    fechaCierre: new FormControl("", [Validators.required]),
    montos: new FormControl("", [Validators.required, Validators.minLength(7)]),
    tomaContacto: new FormControl("", [Validators.required]),
    newEtapaVentas: new FormControl("", [Validators.required]),
    cliente: new FormControl("", [Validators.required]),
  });

  ahora: any;

  constructor(
    public modalCtrl: ModalController,
    public toastController: ToastController,
    private tokenService: TokenService,
    private CrmService: CrmService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    const datePite = new DatePipe("es-ES");
    this.ahora = datePite.transform(new Date(), "yyyy-MM-dd");
    const date = new Date();
    const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm");
    const stringDate = formattedDate.toString();
    this.fechaCierreString = stringDate;
  }

  async cargarClientes() {
    this.clientes = [];
    do {
      var response = await this.CrmService.consultarClientesPorComercial(
        this.pagConsultaCliente,
        20
      );
      this.cantPaginasCliente = response["meta"]["total-pages"];
      console.log(this.cantPaginasCliente);
      for (var index in response["data"]) {
        var cliente = response["data"][index];
        //console.log(cliente);
        let now = moment();
        let fecha_modificacion = moment(
          cliente["attributes"]["date_modified"]
        ).toDate();
        let semanas = now.diff(fecha_modificacion, "weeks");
        this.clientes.push(
          new Cliente(
            cliente["id"],
            cliente["attributes"]["name"],
            "", //cliente['attributes']['tipo_documento_c'],
            "", //cliente['attributes']['nit_c'],
            cliente["attributes"]["description"],
            cliente["attributes"]["billing_address_street"],
            cliente["attributes"]["billing_address_city"],
            cliente["attributes"]["billing_address_state"],
            cliente["attributes"]["billing_address_country"],
            cliente["attributes"]["phone_office"],
            cliente["attributes"]["account_type"],
            cliente["attributes"]["email1"],
            moment(cliente["attributes"]["date_entered"]).toDate(),
            moment(cliente["attributes"]["date_modified"]).toDate(),
            cliente["attributes"]["estado_c"],

          )
        );
      }
      this.pagConsultaCliente += 1;
    } while (this.pagConsultaCliente <= this.cantPaginasCliente);
  }

  buscarClientes(event: { component: IonicSelectableComponent; text: string }) {
    let text = event.text.trim().toLowerCase();
    event.component.startSearch();

    if (!text) {
      // Close any running subscription.
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      event.component.items = [];
      event.component.endSearch();
      return;
    }

    this.subscription = this.CrmService.buscarClientes(text).subscribe(
      (response) => {
        // Subscription will be closed when unsubscribed manually.
        if (this.subscription.closed) {
          return;
        }
        this.clientes = [];
        for (const index in response["data"]) {
          var cliente = response["data"][index];

          this.clientes.push(
            new Cliente(
              cliente["id"],
              cliente["attributes"]["name"],
              "", //cliente['attributes']['tipo_documento_c'],
              "", //cliente['attributes']['nit_c'],
              cliente["attributes"]["description"],
              cliente["attributes"]["billing_address_street"],
              cliente["attributes"]["billing_address_city"],
              cliente["attributes"]["billing_address_state"],
              cliente["attributes"]["billing_address_country"],
              cliente["attributes"]["phone_office"],
              cliente["attributes"]["account_type"],
              cliente["attributes"]["email1"],
              moment(cliente["attributes"]["date_entered"]).toDate(),
              moment(cliente["attributes"]["date_modified"]).toDate(),
              cliente["attributes"]["estado_c"],

            )
          );
        }
        event.component.items = this.clientes;
        event.component.endSearch();
      }
    );
  }

  crearNegocio() {
    this.negocio.nombre_cliente = this.negocio.nombre;
    this.CrmService.crearNegocio(this.negocio).subscribe(
      async (response) => {
        const toast = await this.toastController.create({
          color: "success",
          message: "Se ha creado la oportunidad.",
          duration: 2000,
        });
        toast.present();
        this.negocio.id = response["data"]["id"];
        this.negocios.push(this.negocio);
      },

      async (err) => {
        console.log(err);
        const toast = await this.toastController.create({
          color: "danger",
          message: "Error... no se creó la oportunidad",
          duration: 2000,
        });
        toast.present();
      }
    );
    this.cerrarModal();
  }

  obtenerEtiquetaEtapaVenta(etapasVenta: JSON, negocio: Negocio) {
    for (var index in etapasVenta) {
      if (etapasVenta[index].valor == negocio.etapa_venta) {
        return etapasVenta[index].etiqueta;
      }
    }
  }
  recalcularValorNegocios(negocios: Negocio[]) {
    let valorNegocios = 0;
    for (var index in negocios) {
      valorNegocios += Number(negocios[index].monto);
    }
    return valorNegocios;
  }

  clienteChange(event: { component: IonicSelectableComponent; value: any }) {
    this.actualizarIdCliente(event.value.id);
    this.negocios = this.CrmService.consultarNegociosPorRelacion(
      "Accounts",
      event.value.id
    );
  }

  cerrarModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }

  actualizarNombre(nombre: string) {
    this.negocio.nombre = nombre;
  }

  actualizarFechaCierreEvento(ev: any) {
    let fechaCierre = ev.detail.value;
    this.fechaCierreString = fechaCierre;
    const date = parseISO(this.fechaCierreString);
    this.negocio.fecha_cierre = date;
    //this.cd.detectChanges();
  }
  actualizarFechaCierre(fechaCierre: string) {
    const date = parseISO(this.fechaCierreString);
    this.negocio.fecha_cierre = date;
  }

  actualizarMonto(monto: number) {
    console.log(monto);
    this.negocio.monto = monto;
  }

  actualizarTipo(tipo: string) {
    this.negocio.tipo_negocio = tipo;
  }

  actualizarTomaContacto(tomaContacto: string) {
    this.negocio.toma_contacto = tomaContacto;
  }

  actualizarEtapa(etapaVentas: string) {
    this.negocio.etapa_venta = etapaVentas;
  }

  actualizarIdCliente(idCliente: string) {
    this.negocio.id_cliente = idCliente;
  }

  actualizarDescripcion(descripcion: string) {
    this.negocio.descripcion = descripcion;
  }
}
