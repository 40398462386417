import { Injectable } from '@angular/core';

const TOKEN_KEY = 'AuthToken';
const REFRESH_TOKEN = 'RefreshToken'
const USERNAME_KEY = 'AuthUserName';
const AUTHORITIES_KEY = 'AutAuthorities';

const URI = 'BaseUri';
const USER_ID = 'UserID';
const DB_NAME = 'NameDB';
const PARAMETROS = 'Parametros';
const USER_ROL = 'UserRol';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  roles: Array<string> = [];

  constructor() { }

  public setToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public setRefreshToken(refreshToken: string): void {
    window.sessionStorage.removeItem(REFRESH_TOKEN);
    window.sessionStorage.setItem(REFRESH_TOKEN, refreshToken);
  }

  public getRefreshToken(): string {
    return sessionStorage.getItem(REFRESH_TOKEN);
  }

  public setUserName(userName: string): void {
    window.sessionStorage.removeItem(USERNAME_KEY);
    window.sessionStorage.setItem(USERNAME_KEY, userName);
  }

  public getUserName(): string {
    return sessionStorage.getItem(USERNAME_KEY);
  }

  public setUri(uri: string): void {
    window.localStorage.removeItem(URI);
    window.localStorage.setItem(URI, uri);
  }

  public getUri(): string {
    return localStorage.getItem(URI);
  }

  public setParametros(parametros: string): void {
    window.localStorage.removeItem(PARAMETROS);
    window.localStorage.setItem(PARAMETROS, parametros);
  }

  public getParametros(): string {
    return localStorage.getItem(PARAMETROS);
  }

  public setUserID(userID: string): void {
    window.sessionStorage.removeItem(USER_ID);
    window.sessionStorage.setItem(USER_ID, userID);
  }

  public getUserID(): string {
    return sessionStorage.getItem(USER_ID);
  }

  public setDBName(nombreDB: string): void {
    window.sessionStorage.removeItem(DB_NAME);
    window.sessionStorage.setItem(DB_NAME, nombreDB);
  }

  public getDBName(): string {
    return sessionStorage.getItem(DB_NAME);
  }


  public setUserRol(userRol: string): void {
    window.sessionStorage.removeItem(USER_ROL);
    window.sessionStorage.setItem(USER_ROL, userRol);
  }


  public getUserRol(): string {
    return sessionStorage.getItem(USER_ID);
  }

  public setAuthorities(authorities: string[]): void {
    window.sessionStorage.removeItem(AUTHORITIES_KEY);
    window.sessionStorage.setItem(AUTHORITIES_KEY, JSON.stringify(authorities));
  }

  public getAuthorities(): string[] {
    this.roles = [];
    if (sessionStorage.getItem(AUTHORITIES_KEY)) {
      JSON.parse(sessionStorage.getItem(AUTHORITIES_KEY)).forEach(authority => {
        this.roles.push(authority.authority);
      });
    }
    return this.roles;
  }

  public logOut(): void {
    window.sessionStorage.clear();
    window.localStorage.clear();
  }
}
