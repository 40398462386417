import { TokenService } from './token.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({providedIn: "root"})
export class AuthGuardService {

  constructor(public auth: AuthService, 
              public tokenService: TokenService,
              public router: Router) {}
              
  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      this.tokenService.logOut();
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}
