<ion-header>
  <ion-toolbar>
    <ion-icon
    style="font-size: x-large"
      slot="end"
      color="primary"
      (click)="cerrarModal()"
      name="close-circle-outline"
    ></ion-icon>
    <ion-title>FILTRO</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="accion == 'filtrarClientes'">
  <ion-list>
    <ion-item>
      <!-- <ion-label position="stacked">Nombre</ion-label> -->
      <ion-input
      label="Nombre:" labelPlacement="floating" placeholder="Escriba aquí...."
        #nombre
        (ionChange)="actualizarNombre(nombre.value)"
      ></ion-input>
    </ion-item>
    <ion-item position="stacked">
      <ion-label position="floating">Tipo de cliente:</ion-label>
      <ion-select #tipo (ionChange)="actualizarTipoCuenta(tipo.value)">
        <ion-select-option
          *ngFor="let tipoCliente of tiposCliente"
          [value]="tipoCliente.valor"
          >{{ tipoCliente.etiqueta }}</ion-select-option
        >
      </ion-select>
    </ion-item>
  </ion-list>
  <ion-row style="height: 10px"></ion-row>
  <ion-button class="button" (click)="filtrarClientes()">Buscar</ion-button>
</ion-content>

<ion-content *ngIf="accion == 'filtrarNegocios'">
  <ion-list>
    <ion-item>
      <!-- <ion-label position="stacked">Nombre</ion-label> -->
      <ion-input
        label="Nombre:" labelPlacement="floating" placeholder="Escriba aquí...."
        #nombre
        (ionChange)="actualizarNombre(nombre.value)"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-label position="stacked">Tipo de negocio</ion-label>
      <ion-select #tipo (ionChange)="actualizarTipo(tipo.value)">
        <ion-select-option
          *ngFor="let tipoNegocio of tiposNegocio"
          [value]="tipoNegocio.valor"
          >{{ tipoNegocio.etiqueta }}</ion-select-option
        >
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-label position="stacked">Cliente</ion-label>
      <ionic-selectable
        [items]="clientes"
        itemValueField="id"
        itemTextField="nombre"
        [canSearch]="true"
        (onSearch)="buscarClientes($event)"
        (onChange)="clienteChange($event)"
      ></ionic-selectable>
    </ion-item>
  </ion-list>
  <ion-row style="height: 10px"></ion-row>
  <ion-button class="button" (click)="filtrarNegocios()">Buscar</ion-button>
</ion-content>

<ion-content *ngIf="accion == 'filtrarTareas'">
  <ion-list>
    <ion-item>
      <ion-label position="stacked">Prioridad</ion-label>
      <ion-select #prioridad (ionChange)="actualizarPrioridad(prioridad.value)">
        <ion-select-option
          *ngFor="let prioridadTarea of prioridadesTarea"
          [value]="prioridadTarea.valor"
          >{{ prioridadTarea.etiqueta }}</ion-select-option
        >
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-label position="stacked">Estado</ion-label>
      <ion-select #estado (ionChange)="actualizarEstado(estado.value)">
        <ion-select-option
          *ngFor="let estadoTarea of estadosTarea"
          [value]="estadoTarea.valor"
          >{{ estadoTarea.etiqueta }}</ion-select-option
        >
      </ion-select>
    </ion-item>

    <ion-accordion-group #accordionGroup>
      <ion-accordion value="start">
        <ion-item slot="header">
          <ion-label position="stacked">Fecha de inicio</ion-label>
          <ion-note slot="end">
            {{
              dateView | date : "d MMMM y" : undefined : "es" | titlecase
            }}</ion-note
          >
        </ion-item>
        <ion-datetime
          slot="content"
          [(ngModel)]="dateView"
          size="cover"
          presentation="date-time"
          #fechaInicio
          displayFormat="YYYY-MM-DDTHH:mm"
          (ionChange)="actualizarFechaInicio(fechaInicio.value)"
        ></ion-datetime>
      </ion-accordion>
    </ion-accordion-group>

    <ion-item>
      <ion-label position="stacked">Fecha vencimiento</ion-label>
      <ion-datetime
        #fechaVencimiento
        displayFormat="YYYY-MM-DDTHH:mm"
        (ionChange)="actualizarFechaVencimiento(fechaVencimiento.value)"
      ></ion-datetime>
    </ion-item>
    <ion-item>
      <ion-label position="stacked">Cliente</ion-label>
      <ionic-selectable
        [items]="clientes"
        itemValueField="id"
        itemTextField="nombre"
        [canSearch]="true"
        (onSearch)="buscarClientes($event)"
        (onChange)="clienteChange($event)"
      ></ionic-selectable>
    </ion-item>
  </ion-list>
  <ion-row style="height: 10px"></ion-row>
  <ion-button class="button" (click)="filtrarTareas()">Buscar</ion-button>
</ion-content>
