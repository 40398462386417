<ion-header>
  <ion-toolbar>
    <ion-icon
      style="font-size: x-large"
      slot="end"
      color="primary"
      (click)="cerrarModal()"
      name="close-circle-outline"
    ></ion-icon>
    <ion-title *ngIf="accion == 'crearContacto'">Crear contacto</ion-title>
    <ion-title *ngIf="accion == 'editarContacto'"
      >Editar {{ contacto.nombre }} {{ contacto.apellido }}</ion-title
    >
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="validarContacto">
    <ion-list>
      <ion-item>
        <ion-input
          label="Nombres:"
          labelPlacement="floating"
          placeholder="Escriba aquí...."
          formControlName="nombre"
          [(ngModel)]="contacto.nombre"
          [value]="contacto.nombre"
        ></ion-input>
      </ion-item>
      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="
          validarContacto.controls.nombre.errors?.required &&
          (validarContacto.touched || validarContacto.dirty)
        "
      >
        * El campo es obligatorio.
      </ion-label>

      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="validarContacto.controls.nombre.errors?.['minlength'] && (validarContacto.touched || validarContacto.dirty)"
        >* El nombre no está diligenciado o es muy corto.</ion-label
      >

      <ion-item>
        <ion-input
          label="Apellidos:"
          labelPlacement="floating"
          placeholder="Escriba aquí...."
          formControlName="apellido"
          [(ngModel)]="contacto.apellido"
          [value]="contacto.apellido"
        ></ion-input>
      </ion-item>
      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="
          validarContacto.controls.apellido.errors?.required &&
          (validarContacto.touched || validarContacto.dirty)
        "
      >
        * El campo es obligatorio.
      </ion-label>
      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="validarContacto.controls.apellido.errors?.['minlength'] && (validarContacto.touched || validarContacto.dirty)"
        >* El apellido no está diligenciado o es muy corto.</ion-label
      >

      <ion-item>
        <ion-input
          label="Teléfono fijo:"
          labelPlacement="floating"
          placeholder="Escriba aquí...."
          type="tel"
          [(ngModel)]="contacto.telefono"
          [value]="contacto.telefono"
          formControlName="telefono"
        ></ion-input>
      </ion-item>

      <ion-item>
        <ion-input
          label="Teléfono móvil:"
          labelPlacement="floating"
          placeholder="Escriba aquí...."
          type="tel"
          [(ngModel)]="contacto.celular"
          [value]="contacto.celular"
          formControlName="celular"
        ></ion-input>
      </ion-item>

      <ion-label
        color="danger"
        style="margin-left: 5%; text-align: right"
        *ngIf="
          validarContacto.controls.celular.errors?.required &&
          (validarContacto.touched || validarContacto.dirty)
        "
      >
        * El número es obligatorio
      </ion-label>
      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="validarContacto.controls.celular.errors?.['minlength'] && (validarContacto.touched || validarContacto.dirty)"
      >
        * El número debe contener mínimo 10 caracteres.</ion-label
      >

      <ion-item>
        <ion-input
          label="Correo electrónico:"
          labelPlacement="floating"
          placeholder="Escriba aquí...."
          type="email"
          pattern="^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$"
          [(ngModel)]="contacto.email"
          [value]="contacto.email"
          formControlName="email"
        ></ion-input>
      </ion-item>
      <ion-label
        color="danger"
        style="margin-left: 5%; text-align: right"
        *ngIf="
          validarContacto.controls.email.errors?.required &&
          (validarContacto.touched || validarContacto.dirty)
        "
      >
        * El campo es obligatorio.
      </ion-label>

      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="
          validarContacto.controls.email.errors?.email &&
          (validarContacto.touched || validarContacto.dirty)
        "
      >
        * El correo que ingreso no es válido.</ion-label
      >

      <ion-item position="stacked">
        <ion-input
          label="Cargo:"
          labelPlacement="floating"
          placeholder="Escriba aquí...."
          [(ngModel)]="contacto.puesto_trabajo"
          [value]="contacto.puesto_trabajo"
          formControlName="puesto_trabajo"
        ></ion-input>
      </ion-item>

      <ion-item>
        <ion-input
          label="Descripción:"
          labelPlacement="floating"
          placeholder="Escriba aquí...."
          [(ngModel)]="contacto.descripcion"
          [value]="contacto.descripcion"
          formControlName="descripcion"
        ></ion-input>
      </ion-item>
    </ion-list>

    <ion-row style="height: 10px"></ion-row>
    <ion-button
      *ngIf="accion == 'crearContacto'"
      class="button"
      (click)="crearContacto()"
      [disabled]="validarContacto.invalid"
      >Guardar</ion-button
    >
    <ion-button
      *ngIf="accion == 'editarContacto'"
      class="button"
      (click)="actualizarContacto()"
      [disabled]="validarContacto.invalid"
      >Actualizar contacto</ion-button
    >
  </form>
</ion-content>
