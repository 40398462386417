import { AuthService } from './auth.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenService } from './token.service';
import { LoginPage } from './login.page';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(!this.auth.isAuthenticated() && this.tokenService.getToken() != null){
      const usuario = this.login.getUsuario();
      console.log(usuario);
      this.auth.login(usuario).subscribe(
        (data) => {
          console.log(data);
          this.tokenService.setToken(data.access_token);
          this.tokenService.setRefreshToken(data.refresh_token);
        },
        (error) => {
          console.error('Request failed with error');
          this.tokenService.logOut();
          this.router.navigate(['login']);
        }
      );
    }
    let authReq = req;
    const token = this.tokenService.getToken();
    if (token != null) {
      authReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
    }
    return next.handle(authReq);
  }

  constructor(private tokenService: TokenService,
              private auth: AuthService,
              private login: LoginPage,
              public router: Router) { }
}

export const interceptorProvider = [{provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true}];

