import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { CrmService } from 'src/app/api/crm.service';
import { Reunion } from '../../models/reunion.model';
import { Llamada } from '../../models/llamada.model';
import { Tarea } from '../../models/tarea.model';
import { DatePipe } from '@angular/common';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-modal-posponer-actividad',
  templateUrl: './modal-posponer-actividad.component.html',
  styleUrls: ['./modal-posponer-actividad.component.scss'],
})
export class ModalPosponerActividadComponent implements OnInit {
  @Input() llamada: Llamada;
  @Input() reunion: Reunion;
  @Input() tarea: Tarea;
  @Input() actividad: string;
  @Input() accion: string;

  ahora: any;

  constructor(
    public modalCtrl: ModalController,
    public toastController: ToastController,
    private crmService: CrmService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const datePipe = new DatePipe('es-ES');
    this.ahora = datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.reunion.fecha_hora_inicio = this.ahora;
    this.tarea.fecha_realizacion = this.ahora;
  }

  cerrarModal() {
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  actualizarFechaInicio(fechaInicio: Date) {

        this.reunion.fecha_hora_inicio = fechaInicio;
        this.cd.detectChanges();
  }

  actualizarFechaRealizacion(fechaRealizacion: Date) {
    this.tarea.fecha_realizacion = fechaRealizacion;
    this.tarea.fecha_hora_inicio = fechaRealizacion;
    this.tarea.fecha_hora_vencimiento = fechaRealizacion;
    this.cd.detectChanges();
  }

  posponerActividad() {
    switch (this.accion) {
      case 'posponerReunion':
        this.posponerReunion(this.reunion);
        break;
      case 'posponerTarea':
        this.posponerTarea(this.tarea);
        break;
    }
    this.cd.detectChanges();
    this.cerrarModal();
  }

  posponerTarea(tarea: Tarea) {
    this.crmService
      .editarTarea(tarea)
      .pipe(
        catchError(async (err) => {
          console.log(err);
          const toast = await this.toastController.create({
            color: 'danger',
            message: 'Error. No se pudo posponer la tarea',
            duration: 2000
          });
          toast.present();
        })
      )
      .subscribe(async (response) => {
        const toast = await this.toastController.create({
          color: 'success',
          message: 'Tarea pospuesta.',
          duration: 2000
        });
        toast.present();
      });
      this.cd.detectChanges();
  }

  posponerReunion(reunion: Reunion) {
    this.crmService
      .editarReunion(reunion)
      .pipe(
        catchError(async (err) => {
          console.log(err);
          const toast = await this.toastController.create({
            color: 'danger',
            message: 'Error. No se pudo posponer la reunión',
            duration: 2000
          });
          toast.present();
        })
      )
      .subscribe(async (response) => {
        const toast = await this.toastController.create({
          color: 'success',
          message: 'Reunión pospuesta.',
          duration: 2000
        });
        toast.present();
      });
      this.cd.detectChanges();
  }
}
