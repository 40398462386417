import { TokenService } from "../../login/token.service";
import { CrmService } from "src/app/api/crm.service";
import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Cliente } from "../../models/cliente.model";
import { IonicSelectableComponent } from "ionic-selectable";
import { Subscription } from "rxjs";
import * as moment from "moment";
import "moment/locale/es";

@Component({
  selector: "app-modal-filtro",
  templateUrl: "./modal-filtro.component.html",
  styleUrls: ["./modal-filtro.component.scss"],
})
export class ModalFiltroComponent implements OnInit {
  @Input() filtro;
  @Input() clientes: Cliente[];
  @Input() accion: string;

  public subscription: Subscription;

  //
  public tiposCliente = JSON.parse(this.tokenService.getParametros())[
    "Accounts"
  ]["tipos"];
  public tiposNegocio = JSON.parse(this.tokenService.getParametros())[
    "Opportunities"
  ]["tipos"];
  public etapasVenta = JSON.parse(this.tokenService.getParametros())[
    "Opportunities"
  ]["etapasVenta"];
  public prioridadesTarea = JSON.parse(this.tokenService.getParametros())[
    "Tasks"
  ]["prioridades"];
  public estadosTarea = JSON.parse(this.tokenService.getParametros())["Tasks"][
    "estados"
  ];

  constructor(
    public modalCtrl: ModalController,
    private tokenService: TokenService,
    private CrmService: CrmService
  ) {}

  ngOnInit() {}

  cerrarModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    // this.modalCtrl.dismiss({
    //   dismissed: true,
    // });
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  actualizarNombre(nombre: string) {
    this.filtro.nombre = nombre;
  }

  actualizarTelefono(telefono: string) {
    this.filtro.telefono = telefono;
  }

  actualizarEmail(email: string) {
    this.filtro.correo = email;
  }

  actualizarCiudad(ciudad: string) {
    this.filtro.ciudad = ciudad;
  }

  actualizarDepartamento(departamento: string) {
    this.filtro.departamento = departamento;
  }

  actualizarPais(pais: string) {
    this.filtro.pais = pais;
  }

  actualizarTipoCuenta(tipoCuenta: string) {
    this.filtro.tipo = tipoCuenta;
  }

  async filtrarClientes() {
    
    return this.modalCtrl.dismiss(null, 'buscar');
  }

  actualizarFechaCierre(fechaCierre: string) {
    this.filtro.fechaCierre = fechaCierre;
  }

  actualizarMonto(monto: number) {
    this.filtro.monto = monto;
  }

  actualizarTipo(tipo: string) {
    this.filtro.tipo = tipo;
  }

  actualizarEtapa(etapa: string) {
    this.filtro.etapa = etapa;
  }

  filtrarNegocios() {
    return this.modalCtrl.dismiss(null, 'buscar');
  }

  actualizarEstado(estado: string) {
    this.filtro.estado = estado;
  }

  actualizarFechaInicio(fechaVencimiento: string) {
    this.filtro.fechaInicio = fechaVencimiento;
  }

  actualizarFechaVencimiento(fechaVencimiento: string) {
    this.filtro.fechaVencimiento = fechaVencimiento;
  }

  actualizarPrioridad(prioridad: string) {
    this.filtro.prioridad = prioridad;
  }

  buscarClientes(event: { component: IonicSelectableComponent; text: string }) {
    let text = event.text.trim().toLowerCase();
    event.component.startSearch();

    if (!text) {
      // Close any running subscription.
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      event.component.items = [];
      event.component.endSearch();
      return;
    }

    this.subscription = this.CrmService.buscarClientes(text).subscribe(
      (response) => {
        // Subscription will be closed when unsubscribed manually.
        if (this.subscription.closed) {
          return;
        }
        this.clientes = [];
        for (const index in response["data"]) {
          var cliente = response["data"][index];

          this.clientes.push(
            new Cliente(
              cliente["id"],
              cliente["attributes"]["name"],
              "", //cliente['attributes']['tipo_documento_c'],
              "", //cliente['attributes']['nit_c'],
              cliente["attributes"]["description"],
              cliente["attributes"]["billing_address_street"],
              cliente["attributes"]["billing_address_city"],
              cliente["attributes"]["billing_address_state"],
              cliente["attributes"]["billing_address_country"],
              cliente["attributes"]["phone_office"],
              cliente["attributes"]["account_type"],
              cliente["attributes"]["email1"],
              moment(cliente["attributes"]["date_entered"]).toDate(),
              moment(cliente["attributes"]["date_modified"]).toDate(),
              cliente["attributes"]["estado_c"],

            )
          );
        }
        event.component.items = this.clientes;
        event.component.endSearch();
      }
    );
  }

  clienteChange(event: { component: IonicSelectableComponent; value: any }) {
    this.actualizarIdCliente(event.value.id);
  }

  changeNombre(event: { component: IonicSelectableComponent; value: any }) {
    this.actualizarNombre(event.value.nombre);
  }

  actualizarIdCliente(idCliente: string) {
    this.filtro.cliente = idCliente;
  }

  async filtrarTareas() {
    this.cerrarModal();
  }
}
