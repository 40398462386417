import { TokenService } from "../../login/token.service";
import { Component, Input, OnInit } from "@angular/core";
import { ModalController, ToastController } from "@ionic/angular";
import { Cliente } from "../../models/cliente.model";
import { CrmService } from "src/app/api/crm.service";
import {
  FormBuilder,
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

@Component({
  selector: "app-modal-detalle-cliente",
  templateUrl: "./modal-detalle-cliente.component.html",
  styleUrls: ["./modal-detalle-cliente.component.scss"],
})
export class ModalDetalleClienteComponent implements OnInit {
  @Input() clientes: Cliente[];
  @Input() cliente: Cliente;
  @Input() accion: string;

  // private form: UntypedFormGroup;

  public tiposClientes = JSON.parse(this.tokenService.getParametros())["Accounts"]["tipos"];

  validarCliente = this.fb.group({
    nombre: new FormControl("", [Validators.required, Validators.minLength(3)]),
    tipoCuenta: new FormControl("", Validators.required),
    telefono: new FormControl("", [Validators.required, Validators.minLength(10)]),
    email: new FormControl('', [Validators.required, Validators.email]),
    direccion: new FormControl("", []),
    ciudad: new FormControl("", []),
    departamento: new FormControl("", []),
    pais: new FormControl("", []),
    descripcion: new FormControl("", [])

  });

  constructor(
    public modalCtrl: ModalController,
    public toastController: ToastController,
    private tokenService: TokenService,
    private CrmService: CrmService,
    private fb: FormBuilder
  )
  {}

  ngOnInit() {

  }
  onSubmit() {
    this.cliente.nombre = this.validarCliente.value.nombre;
    this.cliente.tipo_cuenta = this.validarCliente.value.tipoCuenta;
    this.cliente.telefono = this.validarCliente.value.telefono;
    this.cliente.email = this.validarCliente.value.email;
    this.cliente.direccion = this.validarCliente.value.direccion;
    this.cliente.descripcion = this.validarCliente.value.descripcion;
    this.cliente.ciudad = this.validarCliente.value.ciudad;
    this.cliente.departamento = this.validarCliente.value.departamento;
    this.cliente.pais = this.validarCliente.value.pais;

  }

  crearCliente() {
    this.onSubmit();
    console.log("en this.cliente", this.cliente );
    this.CrmService.crearCliente(this.cliente).subscribe(
      async (response) => {
        const toast = await this.toastController.create({
          color: "success",
          message: "Se ha creado el cliente.",
          duration: 2000,
        });
        toast.present();
 

        console.log(response);

        this.cliente.id = response["data"]["id"];
        this.clientes.push(this.cliente);
      },
      async (err) => {
        console.log(err);
        const toast = await this.toastController.create({
          color: "danger",
          message: "Error... no se creó el cliente",
          duration: 2000,
        });
        toast.present();
        location.reload();
      }
    );

    this.cerrarModal();
  }

  editarCliente() {
    this.onSubmit();
    this.CrmService.editarCliente(this.cliente).subscribe(
      async (data) => {
        const toast = await this.toastController.create({
          color: "success",
          message: "Se ha actilizado el cliente.",
          duration: 2000,
        });
        toast.present();
      },
      async (err) => {
        console.log(err);
        const toast = await this.toastController.create({
          color: "danger",
          message: "Error... no se actualizó el cliente",
          duration: 2000,
        });
        toast.present();
      }
    );
    this.cerrarModal();
  }

  cerrarModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }
}
