import { AuthGuardService } from './login/auth-guard.service';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';



const routes: Routes = [
  { path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'tareas',
    loadChildren: () => import('./tareas/tareas.module').then( m => m.TareasPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'cliente-detalle',
    loadChildren: () => import('./cliente-detalle/cliente-detalle.module').then( m => m.ClienteDetallePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'ruta-detalle',
    loadChildren: () => import('./ruta-detalle/ruta-detalle.module').then( m => m.RutaDetallePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'registrar-visita',
    loadChildren: () => import('./registrar-visita/registrar-visita.module').then( m => m.RegistrarVisitaPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'crear-pedido',
    loadChildren: () => import('./crear-pedido/crear-pedido.module').then( m => m.CrearPedidoPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'negocios',
    loadChildren: () => import('./negocios/negocios.module').then( m => m.NegociosPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'cal-modal',
    loadChildren: () => import('./pages/cal-modal/cal-modal.module').then( m => m.CalModalPageModule)
  },
  {
    path: 'negocio-detalle',
    loadChildren: () => import('./negocio-detalle/negocio-detalle.module').then( m => m.NegocioDetallePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'pedidos',
    loadChildren: () => import('./cotizacion/cotizacion.module').then( m => m.CotizacionPageModule)
  },
  {
    path: 'pedido-detalle',
    loadChildren: () => import('./pedido-detalle/pedido-detalle.module').then( m => m.PedidoDetallePageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  
  exports: [RouterModule]
})
export class AppRoutingModule {}
