import { ProductoComprado } from "./../models/productoComprado.model";
import { Llamada } from "../models/llamada.model";
import { Contacto } from "../models/contacto.model";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Cliente } from "../models/cliente.model";
import { Reunion } from "../models/reunion.model";
import { Tarea } from "../models/tarea.model";
import { Negocio } from "../models/negocio.model";
import { Pedido } from "src/app//models/pedido.model";
import { empty, Observable, throwError } from "rxjs";

import * as moment from "moment";
import "moment/locale/es";
import { TokenService } from "../login/token.service";
import { Actividad } from "../models/actividad.model";

@Injectable({
  providedIn: "root",
})
export class CrmService {
  public usaCotizacion = JSON.parse(this.tokenService.getParametros())['AOS_Quotes']['visible'];
  public dominios: string = window.sessionStorage.getItem("NameDB");

  // consultarNombresClientes(text: string) {
  //   throw new Error('Method not implemented.');
  // }

  filtrarPedidos(pagSize: number, pagConsulta: number, filtroPedido) {
    var filtro = "";

    if (filtroPedido.nit_c != "") {
      filtro += "&nit_c=" + filtroPedido.nit_c;
    }

    if (filtroPedido.billing_account != "") {
      filtro += "&billing_account=" + filtroPedido.billing_account;
    }

    if (filtroPedido.name != "") {
      filtro += "&name=" + filtroPedido.name;
    }
    if (filtroPedido.number != "") {
      filtro += "&number=" + filtroPedido.number;
    }

    if (filtroPedido.date_start != "") {
      filtro += "&date_start=" + filtroPedido.date_start;
    }

    if (filtroPedido.date_end != "") {
      filtro += "&date_end=" + filtroPedido.date_end;
    }
    if (
      this.usaCotizacion === false) {
      return;
    } else {
      const url =
        "https://appauth.tenebit.co/consultaCotizaciones?" +
        "dominio=" +
        this.dominios +
        filtro;
      return this.http.get(url).toPromise();
    }
  }

  public apiEndpoint: string;
  private apiEnpointBack = "https://appauth.tenebit.co/";
   
  //private apiEnpointBack = "https://003a-190-158-28-73.ngrok-free.app/";

  constructor(private http: HttpClient,     private tokenService: TokenService) {
    this.apiEndpoint = window.localStorage.getItem("BaseUri") + "/Api/V8/";
  }

  sendAudioToServer(audioBlob: Blob, filename: string): Observable<any> {
    const formData = new FormData();
    formData.append('audioFile', audioBlob, filename);


    return this.http.post(this.apiEnpointBack + 'transcribirAudio', formData);
  }

  async consultarClientesPorComercial(pagConsulta: number, pagSize: number) {
    //TODO: Recibir los filtros desde el botón de filtro
    //const url = this.apiEndpoint + 'module/Accounts?filter[operator]=and&filter[assigned_user_id][eq]=' + window.sessionStorage.getItem('UserID');
    const url =
      this.apiEndpoint +
      "module/Accounts?sort=-date_modified&page[size]=" +
      pagSize +
      "&page[number]=" +
      pagConsulta;
    return this.http.get(url).toPromise();
  }

  async consultarContactosPorComercial(pagConsulta: number, pagSize: number) {
    //TODO: Recibir los filtros desde el botón de filtro
    //const url = this.apiEndpoint + 'module/Accounts?filter[operator]=and&filter[assigned_user_id][eq]=' + window.sessionStorage.getItem('UserID');
    const url =
      this.apiEndpoint +
      "module/Contacts?sort=-date_modified&page[size]=" +
      pagSize +
      "&page[number]=" +
      pagConsulta;
    return this.http.get(url).toPromise();
  }

  consultarNegociosPorRelacion(
    tipoRegistro: string,
    idRegistro: string
  ): Negocio[] {
    const url =
      this.apiEndpoint +
      "module/" +
      tipoRegistro +
      "/" +
      idRegistro +
      "/relationships/opportunities?sort=-date_closed";
    var negocios: Negocio[] = [];
    //var negocio: Object = new Object();
    this.http.get(url).subscribe(async (response) => {
      for (var index in response["data"]) {
        const id = response["data"][index]["id"];
        //const urlNegocio = this.apiEndpoint + 'module/Opportunity/' + id;
        const negocio = await this.consultarRegistroID("Opportunities", id);

        if (
          negocio["data"]["attributes"]["sales_stage"] == "-vacío-" ||
          negocio["data"]["attributes"]["sales_stage"] == undefined
        ) {
          negocio["data"]["attributes"]["sales_stage"] =
            "Prospeccion_oportunidad";
        }
        if (
          negocio["data"]["attributes"]["lead_source"] == "-vacío-" ||
          negocio["data"]["attributes"]["lead_source"] == undefined
        ) {
          negocio["data"]["attributes"]["lead_source"] = "Apropiacion";
        }
        negocios.push(
          new Negocio(
            negocio["data"]["id"],
            negocio["data"]["attributes"]["name"],
            negocio["data"]["attributes"]["opportunity_type"],
            negocio["data"]["attributes"]["lead_source"],
            negocio["data"]["attributes"]["description"],
            negocio["data"]["attributes"]["amount"],
            negocio["data"]["attributes"]["sales_stage"],
            moment(negocio["data"]["attributes"]["date_closed"]).toDate(),
            moment(negocio["data"]["attributes"]["date_entered"]).toDate(),
            moment(negocio["data"]["attributes"]["date_modified"]).toDate(),
            negocio["data"]["attributes"]["account_name"],
            negocio["data"]["attributes"]["account_id"]
          )
        );
      }
    });
    return negocios;
  }

  consultarContactosPorRelacion(
    tipoRegistro: string,
    idRegistro: string
  ): Contacto[] {
    const url =
      this.apiEndpoint +
      "module/" +
      tipoRegistro +
      "/" +
      idRegistro +
      "/relationships/contacts";
    var contactos: Contacto[] = [];
    //var negocio: Object = new Object();
    this.http.get(url).subscribe(async (response) => {
      for (var index in response["data"]) {
        const id = response["data"][index]["id"];
        //const urlNegocio = this.apiEndpoint + 'module/Opportunity/' + id;
        const contacto = await this.consultarRegistroID("Contacts", id);
        contactos.push(
          new Contacto(
            contacto["data"]["id"],
            contacto["data"]["attributes"]["first_name"],
            contacto["data"]["attributes"]["last_name"] ? contacto["data"]["attributes"]["last_name"] : null,
            contacto["data"]["attributes"]["first_name"] +
              " " +
              contacto["data"]["attributes"]["last_name"],
            "", //contacto['data']['attributes']['tipo_documento_c'],
            "", //contacto['data']['attributes']['numero_documento_c'],
            contacto["data"]["attributes"]["title"],
            contacto["data"]["attributes"]["description"],
            contacto["data"]["attributes"]["primary_address_street"],
            contacto["data"]["attributes"]["primary_address_city"],
            contacto["data"]["attributes"]["primary_address_state"],
            contacto["data"]["attributes"]["primary_address_country"],
            contacto["data"]["attributes"]["phone_mobile"],
            contacto["data"]["attributes"]["phone_work"],
            contacto["data"]["attributes"]["email1"] ? contacto["data"]["attributes"]["email1"] : null,
            moment(contacto["data"]["attributes"]["date_entered"]).toDate(),
            moment(contacto["data"]["attributes"]["date_modified"]).toDate()
          )
        );
      }
    });
    return contactos;
  }

  consultarActividadesPorRelacion(
    tipoRegistro: string,
    idRegistro: string
  ): Actividad[] {
    
    var actividades: Actividad[] = [];

    //LLAMADAS
    const urlCalls = this.apiEndpoint + "module/" + tipoRegistro + "/" + idRegistro + "/relationships/calls?sort=-date_entered&filter[deleted][eq]=0";
    this.http.get(urlCalls).subscribe(async (response) => {
      for (var index in response["data"]) {
        const id = response["data"][index]["id"];
        //const urlNegocio = this.apiEndpoint + 'module/Opportunity/' + id;
        const llamada = await this.consultarRegistroID("Calls", id);
        var fecha = new Date(llamada["data"]["attributes"]["date_start"]);
        fecha.setHours(fecha.getHours() - 5);
        
        actividades.push(
          new Actividad(
            llamada["data"]["id"],
            llamada["data"]["attributes"]["name"],
            llamada["data"]["attributes"]["status"],
            fecha,
            llamada["data"]["attributes"]["parent_type"],
            llamada["data"]["attributes"]["parent_name"],
            llamada["data"]["attributes"]["parent_id"],
            llamada["data"]["attributes"]["description"],
            llamada["data"]["attributes"]["assigned_user_name"],
            "Llamada"            
          )
        );
      }
    });


        //TODO: REUNIONES
    const urlMeetings = this.apiEndpoint + "module/" + tipoRegistro + "/" + idRegistro + "/relationships/meetings?sort=-date_entered&filter[deleted][eq]=0";
    this.http.get(urlMeetings).subscribe(async (response) => {

      for (var index in response["data"]) {
        const id = response["data"][index]["id"];
        //const urlNegocio = this.apiEndpoint + 'module/Opportunity/' + id;
        const reunion = await this.consultarRegistroID("Meetings", id);


        var fecha = new Date(reunion["data"]["attributes"]["date_start"]);
        fecha.setHours(fecha.getHours() - 5);

        actividades.push(
          new Actividad(
            reunion["data"]["id"],
            reunion["data"]["attributes"]["name"],
            reunion["data"]["attributes"]["status"],
            fecha,
            reunion["data"]["attributes"]["parent_type"],
            reunion["data"]["attributes"]["parent_name"],
            reunion["data"]["attributes"]["parent_id"],
            reunion["data"]["attributes"]["description"],
            reunion["data"]["attributes"]["assigned_user_name"],
            "Reunión"            
          )
        );
      }
    });

 
    //  TODO: TAREAS
    const urlTasks = this.apiEndpoint + "module/" + tipoRegistro + "/" + idRegistro + "/relationships/tasks?sort=-date_entered&filter[deleted][eq]=0";
    this.http.get(urlTasks).subscribe(async (response) => {

      for (var index in response["data"]) {
        const id = response["data"][index]["id"];
        //const urlNegocio = this.apiEndpoint + 'module/Opportunity/' + id;
        const tarea = await this.consultarRegistroID("Tasks", id);
        var fecha = new Date(tarea["data"]["attributes"]["date_start"]);
        fecha.setHours(fecha.getHours() - 5);

        actividades.push(
          new Actividad(
            tarea["data"]["id"],
            tarea["data"]["attributes"]["name"],
            tarea["data"]["attributes"]["status"],
            fecha,
            tarea["data"]["attributes"]["parent_type"],
            tarea["data"]["attributes"]["parent_name"],
            tarea["data"]["attributes"]["parent_id"],
            tarea["data"]["attributes"]["description"],
            tarea["data"]["attributes"]["assigned_user_name"],
            "Tarea"  
          )
        );
      }
    });

    
    // console.log("Todas las Activiades cliente: ", actividades);
    return actividades;
  }

  // consultarActividadesOporPorRelacion(
  //   tipoRegistro: string,
  //   idRegistro: string
  // ): Actividad[] {
    
  //   var actividades: Actividad[] = [];

  //   const urlCalls = this.apiEndpoint + "module/" + tipoRegistro + "/" + idRegistro + "/relationships/calls?sort=-date_entered&filter[deleted][eq]=0";
  //   this.http.get(urlCalls).subscribe(async (response) => {

  //     for (var index in response["data"]) {
  //       const id = response["data"][index]["id"];
  //       //const urlNegocio = this.apiEndpoint + 'module/Opportunity/' + id;
  //       const llamada = await this.consultarRegistroID("Calls", id);
  //       var fecha = new Date(llamada["data"]["attributes"]["date_start"]);
  //       fecha.setHours(fecha.getHours() - 5);

  //       actividades.push(
  //         new Actividad(
  //           llamada["data"]["id"],
  //           llamada["data"]["attributes"]["name"],
  //           llamada["data"]["attributes"]["status"],
  //           fecha,
  //           llamada["data"]["attributes"]["parent_type"],
  //           llamada["data"]["attributes"]["parent_name"],
  //           llamada["data"]["attributes"]["parent_id"],
  //           llamada["data"]["attributes"]["description"],
  //           "Llamada"  
  //         )
  //       );
  //     }
  //   });


  //   const urlMeetings = this.apiEndpoint + "module/" + tipoRegistro + "/" + idRegistro + "/relationships/meetings?sort=-date_entered&filter[deleted][eq]=0";
  //   this.http.get(urlMeetings).subscribe(async (response) => {

  //     for (var index in response["data"]) {
  //       const id = response["data"][index]["id"];
  //       // const urlNegocio = this.apiEndpoint + 'module/Opportunity/' + id;
  //       const reuinion = await this.consultarRegistroID("Meetings", id);
  //       var fecha = new Date(reuinion["data"]["attributes"]["date_start"]);
  //       fecha.setHours(fecha.getHours() - 5);

  //       actividades.push(
  //         new Actividad(
  //           reuinion["data"]["id"],
  //           reuinion["data"]["attributes"]["name"],
  //           reuinion["data"]["attributes"]["status"],
  //           fecha,
  //           reuinion["data"]["attributes"]["parent_type"],
  //           reuinion["data"]["attributes"]["parent_name"],
  //           reuinion["data"]["attributes"]["parent_id"],
  //           reuinion["data"]["attributes"]["description"],
  //           "Reunión"  
  //         )
  //       );
  //     }
  //   });

  //   const urlTasks = this.apiEndpoint + "module/" + tipoRegistro + "/" + idRegistro + "/relationships/tasks?sort=-date_entered&filter[deleted][eq]=0";
  //   this.http.get(urlTasks).subscribe(async (response) => {

  //     for (var index in response["data"]) {
  //       const id = response["data"][index]["id"];
  //       //const urlNegocio = this.apiEndpoint + 'module/Opportunity/' + id;
  //       const tarea = await this.consultarRegistroID("Tasks", id);
  //       var fecha = new Date(tarea["data"]["attributes"]["date_start"]);
  //       fecha.setHours(fecha.getHours() - 5);

  //       actividades.push(
  //         new Actividad(
  //           tarea["data"]["id"],
  //           tarea["data"]["attributes"]["name"],
  //           tarea["data"]["attributes"]["status"],
  //           fecha,
  //           tarea["data"]["attributes"]["parent_type"],
  //           tarea["data"]["attributes"]["parent_name"],
  //           tarea["data"]["attributes"]["parent_id"],
  //           tarea["data"]["attributes"]["description"],
  //           "Tarea"  
  //         )
  //       );
  //     }
  //   });

  //   console.log("Todas las Activiades Negocio: ", actividades);
  //   return actividades
  // }

  async consultarContactosActividad(
    tipoActividad: string,
    idActividad: string
  ) {
    const url =
      this.apiEndpoint +
      "module/" +
      tipoActividad +
      "/" +
      idActividad +
      "/relationships/contacts";
    return this.http.get(url).toPromise();
  }

  crearContacto(contacto: Contacto) {
    const tipoRegistro = "Contacts";
    const filtro = "filter[phone_mobile][eq]=" + contacto.celular; // + "&filter[email1][eq]=" + contacto.email; // + "&page[size]=10";
    const atributos = this.obtenerAtributosContacto(contacto);
    return this.crearRegistro(tipoRegistro, atributos);
  }

  editarContacto(contacto: Contacto) {
    const atributos = this.obtenerAtributosContacto(contacto);
    return this.actualizarRegistro("Contacts", contacto.id, atributos);
  }

  obtenerAtributosContacto(contacto: Contacto) {
    const atributos = {
      first_name: contacto.nombre,
      last_name: contacto?.apellido,
      //"tipo_documento_c": contacto.tipo_documento,
      //"numero_documento_c": contacto.numero_documento,
      title: contacto.puesto_trabajo,
      description: contacto.descripcion,
      primary_address_street: contacto.direccion,
      primary_address_city: contacto.ciudad,
      primary_address_state: contacto.departamento,
      primary_address_country: contacto.pais,
      phone_mobile: contacto?.celular,
      phone_work: contacto?.telefono,
      email1: contacto?.email,
    };
    return atributos;
  }

  crearCliente(cliente: Cliente) {
    const tipoRegistro = "Accounts";
    const filtro = "filter[phone_office][eq]=" + cliente.telefono; // + "&filter[email1][eq]=" + contacto.email; // + "&page[size]=10";
    const atributos = this.obtenerAtributosCliente(cliente);
    return this.crearRegistro(tipoRegistro, atributos);
  }

  editarCliente(cliente: Cliente) {
    const atributos = this.obtenerAtributosCliente(cliente);
    return this.actualizarRegistro("Accounts", cliente.id, atributos);
  }

  obtenerAtributosCliente(cliente: Cliente) {
    const atributos = {
      name: cliente.nombre,
      //"tipo_documento_c": contacto.tipo_documento,
      //"numero_documento_c": contacto.numero_documento,
      account_type: cliente?.tipo_cuenta,
      description: cliente.descripcion,
      billing_address_street: cliente.direccion,
      billing_address_city: cliente.ciudad,
      billing_address_state: cliente.departamento,
      billing_address_country: cliente.pais,
      phone_office: cliente?.telefono,
      //"phone_office": contacto.telefono,
      email1: cliente?.email,
    };
    return atributos;
  }

  crearNegocio(negocio: Negocio) {
    const tipoRegistro = "Opportunities";
    const atributos = this.obtenerAtributosNegocio(negocio);
    return this.crearRegistro(tipoRegistro, atributos);
  }

  editarNegocio(negocio: Negocio) {
    const tipoRegistro = "Opportunities";
    const atributos = this.obtenerAtributosNegocio(negocio);
    return this.actualizarRegistro(tipoRegistro, negocio.id, atributos);
  }

  obtenerAtributosNegocio(negocio: Negocio) {
    const atributos = {
      name: negocio.nombre,
      opportunity_type: negocio.tipo_negocio,
      description: negocio.descripcion,
      account_name: negocio.nombre_cliente,
      account_id: negocio.id_cliente,
      amount: negocio.monto,
      lead_source: negocio.toma_contacto,
      sales_stage: negocio.etapa_venta,

      date_closed: moment(negocio.fecha_cierre).format("YYYY-MM-DD").toString(),
    };
    return atributos;
  }

  crearLlamada(llamada: Llamada) {
    const tipoRegistro = "Calls";
    const atributos = this.obtenerAtributosLlamada(llamada);
    return this.crearRegistro(tipoRegistro, atributos);
  }

  editarLlamada(llamada: Llamada) {
    const tipoRegistro = "Calls";
    const atributos = this.obtenerAtributosLlamada(llamada);
    return this.actualizarRegistro(tipoRegistro, llamada.id, atributos);
  }

  obtenerAtributosLlamada(llamada: Llamada) {
    var fecha = new Date(llamada.fecha_hora_inicio);

    const atributos = {
      name: llamada.asunto,
      direction: llamada.tipo,
      status: llamada.estado,
      canal_contacto_c: llamada.canalContacto,
      date_start: moment(fecha.setHours(fecha.getHours() + 5))
        .format("YYYY-MM-DD HH:mm:ss")
        .toString(),
      duration_hours: llamada.duracion_horas,
      duration_minutes: llamada.duracion_minutos,
      description: llamada.descripcion,
      parent_type: llamada.parent_type,
      parent_name: llamada.parent_name,
      parent_id: llamada.parent_id,
      contact_name: llamada.contact_name,
      contact_id: llamada.contact_id,
    };
    return atributos;
  }

  crearTarea(tarea: Tarea) {
    const tipoRegistro = "Tasks";
    const atributos = this.obtenerAtributosTarea(tarea);
    return this.crearRegistro(tipoRegistro, atributos);
  }

  editarTarea(tarea: Tarea) {
    const tipoRegistro = "Tasks";
    const atributos = this.obtenerAtributosTarea(tarea);
    return this.actualizarRegistro(tipoRegistro, tarea.id, atributos);
  }

  obtenerAtributosTarea(tarea: Tarea) {
    var fechaInicio = new Date(tarea.fecha_hora_inicio);
    var fechaRealizacion = new Date(tarea.fecha_realizacion);
    var fechaVencimiento = new Date(tarea.fecha_hora_vencimiento);

    const atributos = {
      name: tarea.asunto,
      status: tarea.estado,
      date_start: moment(fechaInicio.setHours(fechaInicio.getHours() + 5))
        .format("YYYY-MM-DD HH:mm:ss")
        .toString(),
      date_due: moment(
        fechaVencimiento.setHours(fechaVencimiento.getHours() + 5)
      )
        .format("YYYY-MM-DD HH:mm:ss")
        .toString(),
      //"fecha_realizacion_c": moment(fechaRealizacion.setHours(fechaRealizacion.getHours() + 5)).format('YYYY-MM-DD HH:mm:ss').toString(),
      priority: tarea.prioridad,
      description: tarea.descripcion,
      parent_type: tarea.parent_type,
      parent_name: tarea.parent_name,
      parent_id: tarea.parent_id,
      contact_name: tarea.contact_name,
      contact_id: tarea.contact_id,
    };
    if (window.localStorage.getItem("BaseUri").includes("crmtenebit")) {
      atributos["fecha_realizacion_c"] = moment(
        fechaRealizacion.setHours(fechaRealizacion.getHours() + 5)
      )
        .format("YYYY-MM-DD HH:mm:ss")
        .toString();
    }

    return atributos;
  }

  crearReunion(reunion: Reunion) {
    const tipoRegistro = "Meetings";
    const atributos = this.obtenerAtributosReunion(reunion);
    return this.crearRegistro(tipoRegistro, atributos);
  }

  editarReunion(reunion: Reunion) {
    const tipoRegistro = "Meetings";
    const atributos = this.obtenerAtributosReunion(reunion);
    return this.actualizarRegistro(tipoRegistro, reunion.id, atributos);
  }

  obtenerAtributosReunion(reunion: Reunion) {
    var fecha = new Date(reunion.fecha_hora_inicio);

    const atributos = {
      name: reunion.asunto,
      status: reunion.estado,
      date_start: moment(fecha.setHours(fecha.getHours() + 5))
        .format("YYYY-MM-DD HH:mm:ss")
        .toString(),
      duration_hours: reunion.duracion_horas,
      duration_minutes: reunion.duracion_minutos,
      location: reunion.lugar,
      description: reunion.descripcion,
      parent_type: reunion.parent_type,
      parent_name: reunion.parent_name,
      parent_id: reunion.parent_id,
      contact_name: reunion.contact_name,
      contact_id: reunion.contact_id,
      jjwg_maps_lat_c: reunion.latitud,
      jjwg_maps_lng_c: reunion.longitud,
    };
    return atributos;
  }

  eliminarRegistro(tipoRegistro: string, id: string) {
    const url = this.apiEndpoint + "module/" + tipoRegistro + "/" + id;
    return this.http.delete(url);
  }

  async crearActualizarRegistro(
    tipoRegistro: string,
    filtros: string,
    atributos: Object
  ) {
    const response = await this.consultarRegistroFiltro(tipoRegistro, filtros);
    console.log(response["data"]);
    if (response["data"].length != 0) {
      const idRegistro = response["data"]["0"]["id"];
      return this.actualizarRegistro(
        tipoRegistro,
        idRegistro,
        atributos
      ).toPromise();
    } else {
      return this.crearRegistro(tipoRegistro, atributos).toPromise();
    }
  }

  async consultarRegistroID(tipoRegistro: string, idRegistro: string) {
    const url = this.apiEndpoint + "module/" + tipoRegistro + "/" + idRegistro;
    return this.http.get(url).toPromise();
  }

  async consultarRegistroFiltro(tipoRegistro: string, filtros: string) {
    const url =
      this.apiEndpoint +
      "module/" +
      tipoRegistro +
      "?filter[operator]=and&" +
      filtros;
    return this.http.get(url).toPromise();
  }

  crearRegistro(tipoRegistro: string, atributos: Object) {
    const url = this.apiEndpoint + "module";
    atributos["assigned_user_id"] = window.sessionStorage.getItem("UserID");
    const body = {
      data: {
        type: tipoRegistro,
        attributes: atributos,
      },
    };
    return this.http.post(url, body);
  }

  actualizarRegistro(tipoRegistro: string, id: string, atributos: Object) {
    const url = this.apiEndpoint + "module";
    const body = {
      data: {
        type: tipoRegistro,
        id: id,
        attributes: atributos,
      },
    };
    var res = this.http.patch(url, body);
    console.log(res);
    return res;
  }

  crearRelacion(
    tipoRegistro1: string,
    idRegistro1: string,
    tipoRegistro2: string,
    idRegistro2: string
  ) {
    const url =
      this.apiEndpoint +
      "module/" +
      tipoRegistro1 +
      "/" +
      idRegistro1 +
      "/relationships";
    const body = {
      data: {
        type: tipoRegistro2,
        id: idRegistro2,
      },
    };
    console.log(body);
    return this.http.post(url, body);
  }

  consultarReunionesPorComercial() {
    const url = this.apiEndpoint + "module/Meetings";
    return this.http.get(url);
  }

  consultarTareasPorComercial(paginaConsulta: number) {
    const url =
      this.apiEndpoint +
      "module/Tasks?sort=-date_modified&page[size]=20&page[number]=" +
      paginaConsulta;
    return this.http.get(url);
  }

  async consultarNegociosPorComercial(
    etapaVentas: string,
    paginaConsulta: number
  ) {
    const url =
      this.apiEndpoint +
      "module/Opportunities?filter[sales_stage][eq]=" +
      etapaVentas +
      "&sort=-date_modified&page[size]=20&page[number]=" +
      paginaConsulta;
    return this.http.get(url).toPromise();
  }

  async consultarReunionesPorFecha(fechaInicio: Date, fechaFin: Date) {
    const filtro =
      "filter[date_start][gte]=" +
      fechaInicio.toISOString() +
      "&filter[date_end][lte]=" +
      fechaFin.toISOString() +
      "&page[size]=" +
      100 +
      "&page[number]=" +
      1;

    const url = this.apiEndpoint + "module/Meetings?" + filtro;

    return this.http.get(url).toPromise();
  }

  consultarActividadesPendientesDiarias(actividad: string) {
    const fechaAnterior: Date = new Date();
    const fechaSiguiente: Date = new Date();
    //fechaAnterior.setDate(fechaAnterior.getDate() - 1);
    fechaSiguiente.setDate(fechaSiguiente.getDate() + 1);

    const filtro =
      "filter[date_start][gt]=" +
      moment(fechaAnterior).format("YYYYMMDD").toString() +
      "&filter[date_start][lt]=" +
      moment(fechaSiguiente).format("YYYYMMDD").toString();
    const url = this.apiEndpoint + "module/" + actividad + "?" + filtro;
    return this.http.get(url);
  }


  async filtrarClientes(nroPagina: number, filtroCliente) {

    console.log(filtroCliente);    
    

      let url = this.apiEndpoint + "module/Accounts";
      url += `?sort=-date_modified`;
      url += `&filter[operator]=and`;

      if (filtroCliente.nombre != "") {
        url += `&filter[name][like]=${encodeURIComponent("%"+filtroCliente.nombre+"%")}`;
      }
      if (filtroCliente.tipo != "") {
        url += `&filter[account_type][eq]=${encodeURIComponent(filtroCliente.tipo)}`;
      }
      url += `&page[size]=20`;
      url += `&page[number]=${nroPagina}`;
      url += `&filter[deleted][eq]=0`;
    
      return this.http.get(url).toPromise();
  }

  filtrarNegocios(etapaNegocio: string, nroPagina: number, filtroNegocio) {
    var filtro = "";

    if (filtroNegocio.nombre != undefined && filtroNegocio.nombre != "") {
      filtro = filtro + "&filter[name][like]=%" + filtroNegocio.nombre + "%";
    }
    if (
      filtroNegocio.fechaCierre != undefined &&
      filtroNegocio.fechaCierre != ""
    ) {
      filtro = filtro + "&filter[date_closed][eq]=" + filtroNegocio.fechaCierre;
    }
    if (filtroNegocio.etapa != undefined && filtroNegocio.etapa != "") {
      filtro = filtro + "&filter[sales_stage][eq]=" + filtroNegocio.etapa;
    }
    if (filtroNegocio.monto != undefined && filtroNegocio.monto != "") {
      filtro = filtro + "&filter[amount][eq]=" + filtroNegocio.monto;
    }
    if (filtroNegocio.tipo != undefined && filtroNegocio.tipo != "") {
      filtro = filtro + "&filter[opportunity_type][eq]=" + filtroNegocio.tipo;
    }

    const url =
      this.apiEndpoint + "module/Opportunities?sort=-date_modified" + filtro;
    return this.http.get(url).toPromise();
  }

  buscarClientes(text: string) {
    const url =
      this.apiEndpoint + "module/Accounts?filter[name][like]=%" + text + "%";
    return this.http.get(url);
  }

  buscarContactos(text: string) {
    const url =
      this.apiEndpoint +
      "module/Contacts?filter[first_name][like]=%" +
      text +
      "%";
    return this.http.get(url);
  }

  agregarUsuarioActividad(actividad: string, idActividad: string) {
    var parametros = {
      valores:
        "'" +
        idActividad +
        "', '" +
        window.sessionStorage.getItem("UserID") +
        "', 1, 'accept', 0",
      database: window.sessionStorage.getItem("NameDB"),
    };
    switch (actividad) {
      case "Meetings":
        parametros["tabla"] = "meetings_users";
        parametros["campos"] =
          "meeting_id, user_id, required, accept_status, deleted";
        break;
      case "Calls":
        parametros["tabla"] = "calls_users";
        parametros["campos"] =
          "call_id, user_id, required, accept_status, deleted";
        break;
      default:
        return;
    }
    const cabecera = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };

    return this.http.post(
      this.apiEnpointBack + "agregarRegistroMySQL",
      parametros,
      cabecera
    );
  }

  async filtrarNegociosPorCliente(
    etapaNegocio: string,
    nroPagina: number,
    filtroNegocio
  ): Promise<Negocio[]> {
    const url =
      this.apiEndpoint +
      "module/Accounts/" +
      filtroNegocio.cliente +
      "/relationships/opportunities";
    var negocios: Negocio[] = [];
    const response = await this.http.get(url).toPromise();

    for (var index in response["data"]) {
      const id = response["data"][index]["id"];
      const negocio = await this.consultarRegistroID("Opportunities", id);
      var agregarNegocio = true;
      if (
        filtroNegocio.nombre != undefined &&
        filtroNegocio.nombre != "" &&
        !negocio["data"]["attributes"]["name"].includes(filtroNegocio.nombre)
      ) {
        agregarNegocio = false;
      }
      if (
        filtroNegocio.fechaCierre != undefined &&
        filtroNegocio.fechaCierre != "" &&
        filtroNegocio.fechaCierre !=
          negocio["data"]["attributes"]["date_closed"]
      ) {
        agregarNegocio = false;
      }
      if (etapaNegocio != negocio["data"]["attributes"]["sales_stage"]) {
        agregarNegocio = false;
      }
      if (
        filtroNegocio.monto != undefined &&
        filtroNegocio.monto != "" &&
        filtroNegocio.monto != negocio["data"]["attributes"]["amount"]
      ) {
        agregarNegocio = false;
      }
      if (
        filtroNegocio.tipo != undefined &&
        filtroNegocio.tipo != "" &&
        filtroNegocio.tipo != negocio["data"]["attributes"]["opportunity_type"]
      ) {
        agregarNegocio = false;
      }

      if (agregarNegocio) {
        negocios.push(
          new Negocio(
            negocio["data"]["id"],
            negocio["data"]["attributes"]["name"],
            negocio["data"]["attributes"]["opportunity_type"],
            negocio["data"]["attributes"]["lead_source"],
            negocio["data"]["attributes"]["description"],
            negocio["data"]["attributes"]["amount"],
            negocio["data"]["attributes"]["sales_stage"],
            moment(negocio["data"]["attributes"]["date_closed"]).toDate(),
            moment(negocio["data"]["attributes"]["date_entered"]).toDate(),
            moment(negocio["data"]["attributes"]["date_modified"]).toDate(),
            negocio["data"]["attributes"]["account_name"],
            negocio["data"]["attributes"]["account_id"]
          )
        );
      }
    }
    return negocios;
  }

  async filtrarTareas(nroPagina: number, filtroTarea) {
    var filtro = "&filter[operator]=and";

    if (filtroTarea.estado != "") {
      filtro = filtro + "&filter[status][eq]=" + filtroTarea.estado;
    }
    if (filtroTarea.prioridad != "") {
      filtro = filtro + "&filter[priority][eq]=" + filtroTarea.prioridad;
    }
    if (filtroTarea.fechaInicio != "") {
      filtro = filtro + "&filter[date_start][eq]=" + filtroTarea.fechaInicio;
    }
    if (filtroTarea.fechaVencimiento != "") {
      filtro = filtro + "&filter[date_due][eq]=" + filtroTarea.fechaVencimiento;
    }
    const url =
      this.apiEndpoint +
      "module/Tasks?sort=-date_modified" +
      filtro +
      "&page[size]=20&page[number]=" +
      nroPagina;
    return this.http.get(url).toPromise();
  }

  async filtrarTareasPorCliente(filtroTarea): Promise<Tarea[]> {
    const url = this.apiEndpoint + "module/Accounts/" + filtroTarea.cliente + "/relationships/tasks";
    var tareas: Tarea[] = [];
    const response = await this.http.get(url).toPromise();
    for (var index in response["data"]) {
      const id = response["data"][index]["id"];
      const tarea = await this.consultarRegistroID("Tasks", id);
      var agregarTarea = true;
      if (
        filtroTarea.estado != "" &&
        filtroTarea.estado != tarea["data"]["attributes"]["status"]
      ) {
        agregarTarea = false;
      }
      if (
        filtroTarea.prioridad != "" &&
        filtroTarea.prioridad != tarea["data"]["attributes"]["priority"]
      ) {
        agregarTarea = false;
      }
      if (
        filtroTarea.fechaInicio != "" &&
        filtroTarea.fechaInicio != tarea["data"]["attributes"]["date_start"]
      ) {
        agregarTarea = false;
      }
      if (
        filtroTarea.fechaVencimiento != "" &&
        filtroTarea.fechaVencimiento != tarea["data"]["attributes"]["date_due"]
      ) {
        agregarTarea = false;
      }

      if (agregarTarea) {
        var fechaInicio = new Date(tarea["data"]["attributes"]["date_start"]);
        var fechaFin = new Date(tarea["data"]["attributes"]["date_due"]);
        fechaInicio.setHours(fechaInicio.getHours() - 5);
        fechaFin.setHours(fechaFin.getHours() - 5);
        tareas.push(
          new Tarea(
            tarea["data"]["id"],
            tarea["data"]["attributes"]["name"],
            tarea["data"]["attributes"]["status"],
            fechaInicio,
            fechaFin,
            fechaFin,
            tarea["data"]["attributes"]["parent_type"],
            tarea["data"]["attributes"]["parent_name"],
            tarea["data"]["attributes"]["parent_id"],
            tarea["data"]["attributes"]["contact_name"],
            tarea["data"]["attributes"]["contact_id"],
            tarea["data"]["attributes"]["priority"],
            tarea["data"]["attributes"]["description"],
            ""
          )
        );
      }
    }
    return tareas;
  }

  async consultarProductos(pagConsulta: number, pagSize: number) {
    if (
      this.usaCotizacion === false) {
      return;
    } else {
      const url =
        this.apiEndpoint +
        "module/AOS_Products?page[size]=" +
        pagSize +
        "&page[number]=" +
        pagConsulta;
      return this.http.get(url).toPromise();
    }
  }

  consultarPedidosPorRelacion(
    tipoRegistro: string,
    idRegistro: string
  ): Pedido[] {
    const url =
      this.apiEndpoint +
      "module/" +
      tipoRegistro +
      "/" +
      idRegistro +
      "/relationships/aos_quotes";
    var pedidos: Pedido[] = [];
    //var negocio: Object = new Object();
    this.http.get(url).subscribe(async (response) => {
      for (var index in response["data"]) {
        const id = response["data"][index]["id"];
        const pedido = (await this.consultarRegistroID("AOS_Quotes", id))[
          "data"
        ];
        var prodCompradosResponse =
          await this.consultarProductosCompradosPorPedido(pedido["id"]);
        var productosComprados: ProductoComprado[] =
          this.procesarProductosComprados(prodCompradosResponse);

        pedidos.push(
          new Pedido(
            pedido["id"],
            pedido["attributes"]["name"],
            pedido["attributes"]["stage"],
            pedido["attributes"]["approval_status"],
            pedido["attributes"]["invoice_status"], // eliminar
            "", //pedido['attributes']['fecha_realizacion_c'],
            pedido["attributes"]["expiration"],
            pedido["attributes"]["billing_account_id"],
            pedido["attributes"]["billing_account"],
            pedido["attributes"]["billing_contact_id"],
            pedido["attributes"]["billing_contact"],
            pedido["attributes"]["billing_address_street"],
            pedido["attributes"]["billing_address_city"],
            pedido["attributes"]["billing_address_state"],
            pedido["attributes"]["billing_address_country"],
            pedido["attributes"]["description"],
            Number(pedido["attributes"]["total_amt"]),
            Number(pedido["attributes"]["subtotal_amount"]),
            Number(pedido["attributes"]["discount_amount"]),
            Number(pedido["attributes"]["tax_amount"]),
            Number(pedido["attributes"]["shipping_amount"]),
            Number(pedido["attributes"]["total_amount"]),
            productosComprados
          )
        );
      }
    });
    return pedidos;
  }

  procesarProductosComprados(productosComprados) {
    var arrProductosComprados: ProductoComprado[] = [];
    for (var index in productosComprados["data"]) {
      var productoComprado = productosComprados["data"][index];
      arrProductosComprados.push(
        new ProductoComprado(
          productoComprado["id"],
          productoComprado["attributes"]["name"].replace("&gt;", ">"),
          Number(productoComprado["attributes"]["product_qty"]),
          Number(productoComprado["attributes"]["product_unit_price"]),
          Number(productoComprado["attributes"]["product_list_price"]),
          Number(productoComprado["attributes"]["product_discount"]),
          Number(productoComprado["attributes"]["product_discount_amount"]),
          Number(productoComprado["attributes"]["vat"]),
          Number(productoComprado["attributes"]["vat_amt"]),
          Number(productoComprado["attributes"]["product_total_price"])
        )
      );
    }
    return arrProductosComprados;
  }

  async consultarPedidos(pagConsulta: number, pagSize: number) {
    const url =
      this.apiEndpoint +
      "module/AOS_Quotes?page[size]=" +
      pagSize +
      "&page[number]=" +
      pagConsulta;
    return this.http.get(url).toPromise();
  }
  // ** esta funcion genera errores de procesamientos **
  // async consultarProductosCompradosPorPedido(idPedido: string) {
  //   const url =
  //     this.apiEndpoint +
  //     "module/AOS_Quotes/" +
  //     idPedido +
  //     "/relationships/aos_products_quotes";
  //   return this.http.get(url).toPromise();
  // }

  consultarProductosCompradosPorPedido(idPedido: string): Observable<any> {
    const url =
      this.apiEndpoint +
      "module/AOS_Quotes/" +
      idPedido +
      "/relationships/aos_products_quotes";
  
    return this.http.get(url);
  }

  async crearPedido(pedido: Pedido) {
    const tipoRegistro = "AOS_Quotes";
    const atributos = this.obtenerAtributosPedido(pedido);
    return this.crearRegistro(tipoRegistro, atributos).toPromise();
  }

  async actualizarPedido(pedido: Pedido) {
    const tipoRegistro = "AOS_Quotes";
    const atributos = this.obtenerAtributosPedido(pedido);
    return this.actualizarRegistro(
      tipoRegistro,
      pedido.id,
      atributos
    ).toPromise();
  }

  obtenerAtributosPedido(pedido: Pedido) {
    const atributos = {
      name: pedido.nombre,
      stage: pedido.estado_pedido,
      expiration: moment(pedido.fecha_expiracion)
        .format("YYYY-MM-DD")
        .toString(),
      billing_account_id: pedido.id_cliente,
      billing_contact_id: pedido.id_contacto,
      billing_address_street: pedido.direccion,
      billing_address_city: pedido.ciudad,
      billing_address_state: pedido.departamento,
      billing_address_country: pedido.pais,
      description: pedido.descripcion,
      total_amt: pedido.precio_total,
      subtotal_amount: pedido.precio_subtotal,
      discount_amount: pedido.total_descuento,
      tax_amount: pedido.total_iva,
      shipping_amount: pedido.total_envio,
      total_amount: pedido.gran_total,
    };
    if (window.localStorage.getItem("BaseUri").includes("crmtenebit")) {
      atributos["fecha_realizacion_c"] = moment(pedido.fecha_realizacion)
        .format("YYYY-MM-DD")
        .toString();
    }
    return atributos;
  }

  async crearGrupoItems(grupoItems) {
    const tipoRegistro = "AOS_Line_Item_Groups";
    const filtro =
      "filter[parent_type][eq]=AOS_Quotes" +
      "&filter[parent_id][eq]=" +
      grupoItems.id_relacion;
    const atributos = this.obtenerAtributosGrupoItems(grupoItems);
    return this.crearActualizarRegistro(tipoRegistro, filtro, atributos);
  }

  obtenerAtributosGrupoItems(grupoItems) {
    const atributos = {
      parent_type: grupoItems.tipo_relacion,
      parent_id: grupoItems.id_relacion,
      total_amt: grupoItems.total,
      discount_amount: grupoItems.descuento,
      subtotal_amount: grupoItems.subtotal,
      tax_amount: grupoItems.iva,
      total_amount: grupoItems.gran_total,
    };
    return atributos;
  }

  async crearProductoPedido(
    productoComprado: ProductoComprado,
    idPedido: string,
    idGrupoItems: string
  ) {
    const url =
      this.apiEndpoint +
      "module/AOS_Products?filter[name][eq]=" +
      productoComprado.nombre;
    const response = await this.http.get(url).toPromise();
    const tipoRegistro = "AOS_Products_Quotes";
    const filtro =
      "filter[parent_type][eq]=AOS_Quotes" +
      "&filter[parent_id][eq]=" +
      idPedido +
      "&filter[group_id][eq]=" +
      idGrupoItems +
      "&filter[name][eq]=" +
      productoComprado.nombre;
    const atributos = this.obtenerAtributosProductoPedido(
      productoComprado,
      idPedido,
      idGrupoItems,
      response["data"][0]["id"]
    );
    return this.crearActualizarRegistro(tipoRegistro, filtro, atributos);
  }

  obtenerAtributosProductoPedido(
    productoComprado: ProductoComprado,
    idPedido: string,
    idGrupoItems: string,
    idProducto: string
  ) {
    const atributos = {
      parent_type: "AOS_Quotes",
      parent_id: idPedido,
      group_id: idGrupoItems,
      name: productoComprado.nombre,
      product_id: idProducto,
      //"part_number": response['data']['attributes']['part_number'],
      product_unit_price: productoComprado.precio_unitario,
      product_list_price: productoComprado.precio_lista,
      product_total_price: productoComprado.precio_total,
      product_qty: productoComprado.cantidad,
      discount: "Percentage",
      product_discount: productoComprado.porcentaje_descuento,
      product_discount_amount: productoComprado.cantidad_descuento,
      vat: productoComprado.porcentaje_iva,
      vat_amt: productoComprado.cantidad_iva,
    };
    return atributos;
  }

  eliminarProductoPedido(productoComprado: ProductoComprado) {
    if (productoComprado.id != "") {
      console.log("Eliminar producto ", productoComprado.id);
      this.eliminarRegistro(
        "AOS_Products_Quotes",
        productoComprado.id
      ).subscribe(
        async (response) => {
          console.log("Producto eliminado");
        },
        async (err) => {
          console.log("Error eliminando producto");
        }
      );
    }
  }
}
