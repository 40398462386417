import { TokenService } from './token.service';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginUsuario } from './login-usuario';
import { JwtModel } from '../models/jwt-model';
import { JwtHelperService } from '@auth0/angular-jwt';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authURL = 'https://appauth.tenebit.co/';
  //private authURL = 'http://localhost:8000/';


  constructor(private httpClient: HttpClient,
              private tokenService: TokenService,
              public jwtHelper: JwtHelperService) { }

  public login(usuario: LoginUsuario): Observable<JwtModel> {
    const cabecera = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.httpClient.post<JwtModel>(this.authURL + 'login', usuario, cabecera);
  }

  public isAuthenticated(): boolean {
    const token = this.tokenService.getToken();
    const isExpiredToken = this.jwtHelper.isTokenExpired(token);
    //console.log("token expirado: " + isExpiredToken);

    return !isExpiredToken;
  }

  public refreshToken():  Observable<JwtModel> {
    const cabecera = {headers: new HttpHeaders({'Authorization': 'Bearer ' + this.tokenService.getRefreshToken()})};
    const resp = this.httpClient.post<JwtModel>(this.tokenService.getUri + '/Api/access_token', {}, cabecera);
    return resp;
  } 
}
