import { Contacto } from "../../models/contacto.model";
import { Component, Input, OnInit } from "@angular/core";
import { ModalController, ToastController } from "@ionic/angular";
import { CrmService } from "src/app/api/crm.service";
import { FormBuilder, FormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-modal-detalle-contacto",
  templateUrl: "./modal-detalle-contacto.component.html",
  styleUrls: ["./modal-detalle-contacto.component.scss"],
})
export class ModalDetalleContactoComponent implements OnInit {
  @Input() idCliente: string;
  @Input() contactos: Contacto[];
  @Input() contacto: Contacto;
  @Input() accion: string;

  validarContacto = this.fb.group({
    nombre: new FormControl("", [Validators.required, Validators.minLength(3)]),
    apellido: new FormControl("", [Validators.required, Validators.minLength(3)]),
    celular: new FormControl("", [Validators.required, Validators.minLength(10)]),
    telefono: new FormControl(),
    email: new FormControl("", [Validators.required, Validators.email]),
    puesto_trabajo: new FormControl(),
    descripcion: new FormControl(),
  });

  constructor(
    public modalCtrl: ModalController,
    public toastController: ToastController,
    private CrmService: CrmService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {}

  crearContacto() {
    this.CrmService.crearContacto(this.contacto).subscribe(
      async (response) => {
        const toast = await this.toastController.create({
          color: "success",
          message: "Se ha creado el contacto.",
          duration: 2000,
        });
        toast.present();

        console.log(response);

        this.contacto.id = response["data"]["id"];
        this.contactos.push(this.contacto);

        this.CrmService.crearRelacion(
          "Accounts",
          this.idCliente,
          "Contacts",
          this.contacto.id
        ).subscribe(
          async (response) => {
            const toast = await this.toastController.create({
              color: "success",
              message: "Se ha creado la relación contacto con cliente",
              duration: 2000,
            });
            toast.present();
            // location.reload();
          },
          async (err) => {
            console.log(err);
            const toast = await this.toastController.create({
              color: "danger",
              message: "Error... no se creó relación contacto con cliente",
              duration: 2000,
            });
            toast.present();
          }
        );
      },
      async (err) => {
        console.log(err);
        const toast = await this.toastController.create({
          color: "danger",
          message: "Error... no se creó el contacto",
          duration: 2000,
        });
        toast.present();
      }
    );

    this.cerrarModal();
  }

  actualizarContacto() {
    this.CrmService.editarContacto(this.contacto).subscribe(
      async (data) => {
        const toast = await this.toastController.create({
          color: "success",
          message: "Se ha actilizado el contacto.",
          duration: 2000,
        });
        toast.present();
      },
      async (err) => {
        console.log(err);
        const toast = await this.toastController.create({
          color: "danger",
          message: "Error... no se actualizó el contacto",
          duration: 2000,
        });
        toast.present();
      }
    );
    this.cerrarModal();
  }

  cerrarModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }

  actualizarNombre(nombre: string) {
    this.contacto.nombre = nombre;
    this.contacto.nombre_completo =
      this.contacto.nombre + " " + this.contacto.apellido;
  }

  actualizarApellido(apellido: string) {
    this.contacto.apellido = apellido;
    this.contacto.nombre_completo =
      this.contacto.nombre + " " + this.contacto.apellido;
  }

  actualizarTelefono(telefono: string) {
    this.contacto.telefono = telefono;
  }

  actualizarCelular(celular: string) {
    this.contacto.celular = celular;
  }

  actualizarEmail(email: string) {
    this.contacto.email = email;
  }

  actualizarPuestoTrabajo(puesto: string) {
    this.contacto.puesto_trabajo = puesto;
  }

  actualizarDescripcion(descripcion: string) {
    this.contacto.descripcion = descripcion;
  }

  actualizarDireccion(direccion: string) {
    this.contacto.direccion = direccion;
  }

  actualizarCiudad(ciudad: string) {
    this.contacto.ciudad = ciudad;
  }

  actualizarDepartamento(departamento: string) {
    this.contacto.departamento = departamento;
  }

  actualizarPais(pais: string) {
    this.contacto.pais = pais;
  }
}
