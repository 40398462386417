import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TokenService } from './login/token.service';

@Injectable({
  providedIn: 'root'
})
export class TableauServerService {

  public url_informes = JSON.parse(this.tokenService.getParametros())["url_informe"];
  public apiEndpoint: string;


  constructor(private http: HttpClient,
    private tokenService: TokenService
    ) {
    //this.apiEndpoint = "http://localhost:8000";
    this.apiEndpoint = this.url_informes;
  }

  consultarUrl(informe: string): Observable<any> {
    const url = this.apiEndpoint; 

    return this.http.get(url);
  }
}
