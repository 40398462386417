import { LoginPage } from './login/login.page';
import { ModalFiltroComponent } from './modals/modal-filtro/modal-filtro.component';
import { ModalDetalleContactoComponent } from './modals/modal-detalle-contacto/modal-detalle-contacto.component';
import { LoginPageModule } from './login/login.module';
import { Component, LOCALE_ID, NgModule, ViewChild } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { TableauServerService } from './tableau-server.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';


//importamos el interceptor
import { interceptorProvider } from './login/interceptor.service';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { ModalDetalleNegocioComponent } from './modals/modal-detalle-negocio/modal-detalle-negocio.component';
import { ModalDetalleActividadComponent } from './modals/modal-detalle-actividad/modal-detalle-actividad.component';
import { ModalPosponerActividadComponent } from './modals/modal-posponer-actividad/modal-posponer-actividad.component';
import { ModalDetalleClienteComponent } from './modals/modal-detalle-cliente/modal-detalle-cliente.component';

import { NgxTimelineModule } from '@frxjs/ngx-timeline';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { registerLocaleData } from '@angular/common';
import  localePy  from '@angular/common/locales/es-CL';
import { ModalDetalleNegocioTabnegociosComponent } from './modals/modal-detalle-negocio-tabnegocios/modal-detalle-negocio-tabnegocios.component';
import { IonicSelectableComponent } from 'ionic-selectable';



registerLocaleData(localePy,'es');




@NgModule({
    declarations: [
        AppComponent,
        ModalDetalleNegocioComponent,
        ModalDetalleActividadComponent,
        ModalPosponerActividadComponent,
        ModalDetalleContactoComponent,
        ModalDetalleClienteComponent,
        ModalFiltroComponent,
        ModalDetalleActividadComponent,
        ModalDetalleNegocioTabnegociosComponent,
        // InformesComponent
        
        
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        LoginPageModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        FormsModule,
        ReactiveFormsModule,
        IonicSelectableComponent,
        CommonModule,
        NgxTimelineModule,
        
     
        
        
        
    ],
    providers: [
        LoginPage,
        JwtHelperService,
        TableauServerService,
        interceptorProvider,
        {provide: LOCALE_ID, useValue: 'es'},
        { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        
    ],
    bootstrap: [AppComponent]
    
})
export class AppModule {}

