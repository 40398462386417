<ion-header>
  <ion-toolbar>
    <ion-icon
    style="margin-right: 1vh; font-size: x-large;"
      slot="end"
      color="primary"
      (click)="cerrarModal()"
      name="close-circle-outline"
    ></ion-icon>
    <ion-title *ngIf="accion == 'posponerReunion'">Posponer reunión</ion-title>
    <ion-title *ngIf="accion == 'posponerTarea'">Posponer tarea</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list *ngIf="accion == 'posponerReunion'">
    <ion-accordion-group #accordionGroup>
      <ion-accordion value="start">
        <ion-item slot="header">
          <ion-label position="stacked"
            >Fecha inicio<ion-text color="danger"> *</ion-text></ion-label
          >
          <ion-text slot="end">{{
            reunion.fecha_hora_inicio | date : "d MMMM y hh:mm " : undefined : "es" | titlecase
          }}</ion-text>
        </ion-item>
        <ion-datetime
          slot="content"
          displayFormat="yyyy-MM-ddThh:mm"
          [(ngModel)]="reunion.fecha_hora_inicio"
          [min]="ahora"
          size="cover"
          presentation="date-time"
          [value]="reunion.fecha_hora_inicio"
          (ionChange)="actualizarFechaInicio(fechaInicio.value)"
        ></ion-datetime>
      </ion-accordion>
    </ion-accordion-group> 
  </ion-list>

  <ion-list *ngIf="accion == 'posponerTarea'">
    <ion-accordion-group #accordionGroup>
      <ion-accordion value="start">
        <ion-item slot="header">
          <ion-label position="stacked"
            >Fecha esperada realización<ion-text color="danger">
              *</ion-text
            ></ion-label
          >
          <ion-text slot="end">{{
            tarea.fecha_realizacion | date : "d MMMM y hh:mm " : undefined : "es" | titlecase
          }}</ion-text>
        </ion-item>
        <ion-datetime
          #fechaRealizacion
          slot="content"
          displayFormat="yyyy-MM-ddThh:mm"
          [(ngModel)]="tarea.fecha_realizacion"
          size="cover"
          presentation="date-time"
          [value]="tarea.fecha_realizacion"
          (ionChange)="actualizarFechaRealizacion(fechaRealizacion.value)"
        ></ion-datetime>
      </ion-accordion>
    </ion-accordion-group>
  </ion-list>
  <ion-button class="button" (click)="posponerActividad()">Guardar</ion-button>
</ion-content>
