export class Negocio {
    constructor(
        public id: string,
        public nombre: string, 
        public tipo_negocio: string, 
        public toma_contacto: string,
        public descripcion: string, 
        public monto: number, 
        public etapa_venta: string, 
        public fecha_cierre: Date, 
        public fecha_creacion: Date, 
        public fecha_modificacion: Date,
        public nombre_cliente: string,
        public id_cliente: string,
    ) {}
}
