<ion-header>
  <ion-toolbar>
    <ion-icon
      style="font-size: x-large"
      slot="end"
      color="primary"
      (click)="cerrarModal()"
      name="close-circle-outline"
    ></ion-icon>
    <ion-title *ngIf="accion == 'crearLlamada'">Crear gestión</ion-title>
    <ion-title *ngIf="accion == 'editarLlamada'">Editar gestión</ion-title>
    <ion-title
      *ngIf="accion == 'crearReunion' || accion == 'crearReunionNegocio'"
      >Crear reunión</ion-title
    >
    <ion-title *ngIf="accion == 'registrarVisita'"
      >Registrar y Georeferenciar Visita</ion-title
    >
    <ion-title *ngIf="accion == 'editarReunion' || accion == 'verReunion'"
      >Editar reunión</ion-title
    >
    <ion-title
      *ngIf="
        accion == 'crearTarea' ||
        accion == 'crearTareaCliente' ||
        accion == 'crearTareaNegocio'
      "
      >Crear tarea</ion-title
    >
    <ion-title *ngIf="accion == 'editarTarea' || accion == 'editarTareaCliente'"
      >Editar tarea</ion-title
    >
  </ion-toolbar>
</ion-header>

<!-- Crear gestión -->
<ion-content *ngIf="actividad == 'llamada'">
  <form [formGroup]="validarGestion">
    <ion-list>
      <ion-item>
        <!-- <ion-label>Tipo Gestión</ion-label> -->

        <ion-segment
          (ionChange)="segmentoTipoGestionChanged($event)"
          value="llamada"
        >
          <ion-segment-button value="llamada">
            <ion-label>Llamada</ion-label>
            <ion-icon name="call"></ion-icon>
          </ion-segment-button>
          <ion-segment-button value="whatsapp">
            <ion-label>WhatsApp</ion-label>
            <ion-icon name="logo-whatsapp"></ion-icon>
          </ion-segment-button>
          <ion-segment-button value="email">
            <ion-label>E-mail</ion-label>
            <ion-icon name="mail-outline"></ion-icon>
          </ion-segment-button>
          <ion-segment-button value="sms">
            <ion-label>SMS</ion-label>
            <ion-icon name="chatbubble-outline"></ion-icon>
          </ion-segment-button>
        </ion-segment>
      </ion-item>
      <ion-item>
        <ion-input
          label="Asunto:"
          labelPlacement="floating"
          placeholder="Escriba aquí...."
          [(ngModel)]="llamada.asunto"
          [value]="llamada.asunto"
          formControlName="asunto"
        ></ion-input>
      </ion-item>

      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="
          validarGestion.controls.asunto.errors?.required &&
          (validarGestion.touched || validarGestion.dirty)
        "
      >
        * El campo es obligatorio
      </ion-label>

      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="validarGestion.controls.asunto.errors?.['minlength'] && (validarGestion.touched || validarGestion.dirty)"
        >* El asunto no está diligenciado o es muy corto.</ion-label
      >

      <ion-item>
        <ion-input
          label="Descripción:"
          labelPlacement="floating"
          placeholder="Escriba aquí...."
          [(ngModel)]="llamada.descripcion"
          [value]="llamada.descripcion"
          formControlName="descripcion"
        ></ion-input>
      </ion-item>

      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="
          validarGestion.controls.descripcion.errors?.required &&
          (validarGestion.touched || validarGestion.dirty)
        "
      >
        * El campo es obligatorio
      </ion-label>

      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="validarGestion.controls.descripcion.errors?.['minlength'] && (validarGestion.touched || validarGestion.dirty)"
      >
        * Ingrese como mínimo 10 caracteres.</ion-label
      >
    </ion-list>

    <ion-button
      *ngIf="accion == 'crearLlamada' && tipoGestion == 'llamada'"
      href="{{ 'tel:' + contacto.celular }}"
      (click)="crearLlamada('llamada')"
      [disabled]="!validarGestion.valid"
    >
      Crear Llamada
    </ion-button>
    <ion-button
      *ngIf="accion == 'crearLlamada' && tipoGestion == 'email'"
      (click)="crearLlamada('correo')"
      (click)="abrirCorreo()"
      [disabled]="!validarGestion.valid"
    >
      Enviar Correo
    </ion-button>
    <ion-button
      *ngIf="accion == 'crearLlamada' && tipoGestion == 'whatsapp'"
      (click)="crearLlamada('whatsapp')"
      (click)="abrirWhatsapp()"
      [disabled]="!validarGestion.valid"
    >
      Enviar mensaje de WhatsApp
    </ion-button>
    <ion-button
      *ngIf="accion == 'crearLlamada' && tipoGestion == 'sms'"
      (click)="crearLlamada('mensaje_texto')"
      type="submit"
      (click)="abrirMensajeTexto()"
      [disabled]="!validarGestion.valid"
    >
      Enviar mensaje SMS
    </ion-button>

    <ion-button
      *ngIf="accion == 'editarLlamada'"
      class="button"
      (click)="editarLlamada('whatsapp')"
      [disabled]="validarGestion.invalid"
      >Actualizar gestión</ion-button
    >
  </form>
</ion-content>

<!-- ///////////// Crear Reunión ///////////// -->

<ion-content *ngIf="actividad == 'reunion'">
  <form [formGroup]="validarReunion">
    <ion-list>
      <ion-item>
        <ion-input
          label="Asunto:"
          labelPlacement="floating"
          placeholder="Escriba aquí...."
          maxlength="30"
          [(ngModel)]="reunion.asunto"
          [value]="reunion.asunto"
          formControlName="asuntos"
        ></ion-input>
      </ion-item>

      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="
          validarReunion.controls.asuntos.errors?.required &&
          (validarReunion.touched || validarReunion.dirty)
        "
      >
        * El campo es obligatorio
      </ion-label>

      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="validarReunion.controls.asuntos.errors?.['minlength'] && (validarReunion.touched || validarReunion.dirty)"
        >* El asunto no está diligenciado o es muy corto.</ion-label
      >
      <!-- El estado es para  reuniones y registar visita pero al cear no es necesario solo al editar para reuniones para Registar 
      vistas no es necesario lo mostrara en  editar y ver reunion  -->
      <ion-item
        *ngIf="actividad == 'editarReunion' || actividade == 'verReunion'"
      >
        <ion-select
          label="Estado:"
          labelPlacement="floating"
          [(ngModel)]="reunion.estado"
          [value]="reunion.estado"
          formControlName="estado"
        >
          <ion-select-option
            *ngFor="let estadoReunion of estadosReunion"
            [value]="estadoReunion.valor"
            (onClick)="actualizarEstado(estado.value)"
            >{{ estadoReunion.etiqueta }}</ion-select-option
          >
        </ion-select>
      </ion-item>
      
<!-- This error  -->
      <ion-accordion-group
        *ngIf="
          accion == 'crearReunion' ||
          accion == 'crearReunionCalendario' ||
          accion == 'editarReunion' ||
          accion == 'verReunion'
        "
      >

      
        <ion-accordion value="start">
          <ion-item slot="header">
            <ion-label position="stacked">Fecha inicio:</ion-label>

            <ion-input
              formControlName="fechasInicio"
              slot="end"
              [(ngModel)]="fechaInicioString"
              [readonly]="true"
            ></ion-input>
          </ion-item>

          <ion-datetime
            locale="es-ES"
            [value]="fechaInicioString"
            (ionChange)="actualizarFechaInicio($event, fechaInicioString)"
            slot="content"
            size="cover"
            [min]="ahora"
          >
            <span slot="time-label">Hora</span>
          </ion-datetime>
        </ion-accordion>
      </ion-accordion-group>

      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="
          validarReunion.controls.fechasInicio.errors?.required &&
          (validarReunion.touched || validarReunion.dirty)
        "
      >
        * Seleccione la fecha
      </ion-label>

      <ion-item>
        <ion-select
          mode="ios"
          interface="action-sheet"
          label="Duración:"
          labelPlacement="floating"
          formControlName="duracionMinutos"
          #duracionMinutos
          (ionChange)="convertirSegundos(duracionMinutos.value)"
        >
          <ion-select-option
            *ngFor="let duracion of duraciones"
            [value]="duracion.valor"
            >{{ duracion.etiqueta }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="
          validarReunion.controls.contacto.errors?.required &&
          (validarReunion.touched || validarReunion.dirty)
        "
      >
        * Seleccione la duración
      </ion-label>

      <ion-item>
        <ion-label position="stacked">Contactos</ion-label>
        <ionic-selectable
          *ngIf="contactos.length > 0"
          [isMultiple]="true"
          [shouldStoreItemValue]="true"
          [(ngModel)]="contactosSeleccionados"
          [items]="contactos"
          itemValueField="id"
          itemTextField="nombre_completo"
          formControlName="contacto"
          [canSearch]="true"
          (onChange)="contactoChange($event)"
        >
        </ionic-selectable>
        <ionic-selectable
          *ngIf="contactos.length == 0"
          [isMultiple]="true"
          [shouldStoreItemValue]="true"
          [(ngModel)]="contactosSeleccionados"
          [items]="contactos"
          itemValueField="id"
          itemTextField="nombre_completo"
          [canSearch]="true"
          formControlName="contacto"
          (onSearch)="buscarContactos($event)"
          (onChange)="contactoChange($event)"
        ></ionic-selectable>
      </ion-item>
      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="
          validarReunion.controls.contacto.errors?.required &&
          (validarReunion.touched || validarReunion.dirty)
        "
      >
        * Seleccione el contacto
      </ion-label>
      <!-- El lugar solo es esencial para reuniones por ende se oculta para registrar visitas -->
      <ion-item
        *ngIf="
          accion == 'crearReunion' ||
          accion == 'crearReunionCalendario' ||
          accion == 'editarReunion' ||
          accion == 'verReunion'
        "
      >
        <!-- <ion-label position="stacked">Lugar</ion-label> -->
        <ion-input
          label="Lugar:"
          labelPlacement="floating"
          placeholder="Escriba aquí...."
          [(ngModel)]="reunion.lugar"
          [value]="reunion.lugar"
          formControlName="lugar"
        ></ion-input>
      </ion-item>

      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="
          validarReunion.controls.lugar.errors?.required &&
          (validarReunion.touched || validarReunion.dirty)
        "
      >
        * El campo es obligatorio
      </ion-label>

      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="validarReunion.controls.lugar.errors?.['minlength'] && (validarReunion.touched || validarReunion.dirty)"
        >* Agregue mínimo 10 caracteres.</ion-label
      >

      <ion-item lines="none" style="margin-top: 2%">
        <ion-label id="comments-label" position="stacked"
          >Transcripción del audio:</ion-label
        >
        <ion-textarea
          aria-labelledby="comments-label"
          type="text"
          [value]="reunion.descripcion"
          formControlName="descripcion"
          style="
            border: 1px solid hsl(0, 0%, 80%);
            border: 1px solid hsl(0,0%, 80%);
            border-radius: 5px;
            text-align: justify;
            background-color: rgb(245, 247, 247);
          "
          cols="100"
          rows="5"
          placeholder="La transcripción se realizará mediante IA"
        ></ion-textarea>
      </ion-item>

      <!-- Microfono -->
      <div align="center" style="margin-top: 1%">
        <ion-button
          *ngIf="!isRecording"
          class="ion-button-voz"
          (click)="iniciarGrabacion()"
          fill="clear"
        >
          <ion-icon
            style="text-align: center"
            class="ion-icon-voz"
            name="mic"
          ></ion-icon>
        </ion-button>

        <ion-button
          *ngIf="isRecording"
          class="ion-button-voz-stop"
          (click)="pararGrabacion()"
          fill="clear"
        >
          <ion-icon
            style="text-align: center"
            class="ion-icon-voz"
            name="mic"
          ></ion-icon>
        </ion-button>
        <ion-img
          *ngIf="play"
          style="width: 15%; margin-top: 0%"
          src="../../../assets/nota_de_voz.gif"
        ></ion-img>

        <div *ngIf="isRecording" class="progress-value">
          <ion-progress-bar
            color="primary"
            [value]="progress"
          ></ion-progress-bar>
          <span>{{ progressTools }}%</span>
        </div>
      </div>
    </ion-list>

    <div style="margin-top: 5%"></div>

    <ion-button
      *ngIf="accion == 'registrarVisita'"
      class="button"
      (click)="registrarVisita()"
      [disabled]="validarReunion.invalid"
      >Registrar y Georeferenciar Visita</ion-button
    >
    <ion-button
      *ngIf="accion == 'crearReunion' || accion == 'crearReunionCalendario'"
      class="button"
      (click)="crearReunion()"
      [disabled]="validarReunion.invalid"
      >Guardar</ion-button
    >
    <ion-button
      *ngIf="accion == 'editarReunion' || accion == 'verReunion'"
      class="button"
      (click)="editarReunion()"
      [disabled]="validarReunion.invalid"
      >Actualizar reunion</ion-button
    >
  </form>
</ion-content>

<!-- ////////////// Crear Tarea /////////////-->
<ion-content *ngIf="actividad == 'tarea'">
  <form [formGroup]="validarTareas">
    <ion-list>
      <ion-item>
        <ion-input
          label="Asunto:"
          labelPlacement="floating"
          placeholder="Escriba aquí...."
          maxlength="30"
          [(ngModel)]="tarea.asunto"
          [value]="tarea.asunto"
          formControlName="asunto"
        ></ion-input>
      </ion-item>
      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="
          validarTareas.controls.asunto.errors?.required &&
          (validarTareas.touched || validarTareas.dirty)
        "
      >
        * El campo es obligatorio
      </ion-label>
      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="validarTareas.controls.asunto.errors?.['minlength'] && (validarTareas.touched || validarTareas.dirty)"
        >* El asunto no está diligenciado o es muy corto.</ion-label
      >
      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="validarTareas.controls.asunto.errors?.['maxlength'] && (validarTareas.touched || validarTareas.dirty)"
        >* Máximo permitido 30 caracteres.</ion-label
      >

      <ion-item
        *ngIf="accion == 'editarTarea' || accion == 'editarTareaCliente'"
      >
        <ion-select
          label="Estado:"
          labelPlacement="floating"
          [(ngModel)]="tarea.estado"
          [value]="tarea.estado"
          formControlName="estado"
        >
          <ion-select-option
            *ngFor="let estadoTarea of estadosTarea"
            [value]="estadoTarea.valor"
            >{{ estadoTarea.etiqueta }}</ion-select-option
          >
        </ion-select>
      </ion-item>
      <ion-label
        color="danger"
        *ngIf="validarTareas.controls.estado.errors?.['minlength'] && (validarTareas.touched || validarTareas.dirty)"
      >
        * Seleccione al menos una opción.</ion-label
      >



      <ion-accordion-group>
        <ion-accordion value="start">
          <ion-item slot="header">
            <ion-label position="stacked">Fecha de realización: </ion-label>
            <ion-input
              formControlName="fechaEsperada"
              slot="end"
              [(ngModel)]="fechaInicioString"
              [readonly]="true"
            ></ion-input>
          </ion-item>
          <ion-datetime
            locale="es"
            [value]="fechaInicioString"
            (ionChange)="actualizarFechaRealizacion($event, fechaInicioString)"
            slot="content"
            size="cover"
            [min]="ahora"
          >
            <span slot="time-label">Hora</span>
          </ion-datetime>
        </ion-accordion>
      </ion-accordion-group>
      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="
          validarTareas.controls.fechaEsperada.errors?.required &&
          (validarTareas.touched || validarTareas.dirty)
        "
      >
        * Seleccione la fecha
      </ion-label>








      <ion-item>
        <ion-input
          label="Descripción:"
          labelPlacement="floating"
          placeholder="Escriba aquí...."
          [(ngModel)]="tarea.descripcion"
          [value]="tarea.descripcion"
          formControlName="descripcion"
        ></ion-input>
      </ion-item>
      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="
          validarTareas.controls.descripcion.errors?.required &&
          (validarTareas.touched || validarTareas.dirty)
        "
      >
        * El campo es obligatorio
      </ion-label>
      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="validarTareas.controls.descripcion.errors?.['minlength'] && (validarTareas.touched || validarTareas.dirty)"
        >* Agregue mínimo 10 caracteres.</ion-label
      >
    </ion-list>
    <ion-row style="height: 10px"></ion-row>
    <ion-button
      *ngIf="accion == 'crearTarea'"
      class="button"
      [disabled]="validarTareas.invalid"
      (click)="crearTarea()"
      >Guardar</ion-button
    >
    <ion-button
      *ngIf="accion == 'editarTarea'"
      class="button"
      [disabled]="validarTareas.invalid"
      (click)="editarTarea()"
      >Actualizar tarea</ion-button
    >
    <ion-button
      *ngIf="accion == 'crearTareaCliente'"
      class="button"
      [disabled]="validarTareas.invalid"
      (click)="crearTareaCliente()"
      >Guardar</ion-button
    >
    <ion-button
      *ngIf="accion == 'editarTareaCliente'"
      class="button"
      [disabled]="validarTareas.invalid"
      (click)="editarTareaCliente()"
      >Actualizar tarea</ion-button
    >
  </form>
</ion-content>
