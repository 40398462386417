

import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from "@angular/core";
import { Directory, Filesystem, } from '@capacitor/filesystem';
import { IonDatetime, ModalController, ToastController } from "@ionic/angular";

import { IonicSelectableComponent } from "ionic-selectable";
import { Subscription } from "rxjs";
import * as moment from "moment";

import { CrmService } from "src/app/api/crm.service";
import { TokenService } from "../../login/token.service";

import { Reunion } from "../../models/reunion.model";
import { Contacto } from "../../models/contacto.model";

import { Llamada } from "../../models/llamada.model";
import { Tarea } from "../../models/tarea.model";
import { Cliente } from "../../models/cliente.model";

import "moment/locale/es";
import { Negocio } from "src/app/models/negocio.model";
import { IonAccordionGroup } from "@ionic/angular";

import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { Geolocation } from "@capacitor/geolocation";
import { VoiceRecorder } from "capacitor-voice-recorder";
import { HttpClient } from "@angular/common/http";
const { parseISO, format } = require("date-fns");

@Component({
  selector: "app-modal-detalle-actividad",
  templateUrl: "./modal-detalle-actividad.component.html",
  styleUrls: ["./modal-detalle-actividad.component.scss"],
})
export class ModalDetalleActividadComponent implements OnInit {
  @ViewChild(IonDatetime, { static: true }) datetime: IonDatetime;
  @ViewChild("accordionGroup", { static: true })
  accordionGroup: IonAccordionGroup;

  @Input() idCliente: string;
  @Input() clientes: Cliente[];
  @Input() llamada: Llamada;
  @Input() llamadas: Llamada[];
  @Input() reunion: Reunion;
  @Input() reuniones: Reunion[];
  @Input() tarea: Tarea;
  @Input() tareas: Tarea[];
  @Input() contactos: Contacto[];
  @Input() negocio: Negocio;
  @Input() actividad: string;
  @Input() accion: string;

  contacto: Contacto;
  public contactoReu: string;
  public ahora: any;
  public fechaInicioString: string;
  public tipoGestion: string = "llamada";
  public duracionSeleccionada;

  totalSegundos: string = "";

  validarGestion = this.fb.group({
    asunto: new FormControl("", [
      Validators.required,
      Validators.minLength(10),
    ]),
    descripcion: new FormControl("", [
      Validators.required,
      Validators.minLength(10),
    ]),
  });

  public validarReunion: FormGroup;

  validarTareas = this.fb.group({
    asunto: new FormControl("", [Validators.required, Validators.minLength(10)]),
    fechaEsperada: new FormControl("", [Validators.required]),
    descripcion: new FormControl("", [Validators.required, Validators.minLength(10),
    ]),
    // Estos campos son de editar tareas
    estado: new FormControl("Not Started", [
      Validators.required,
      Validators.minLength(4),
    ]),
  });

  public subscription: Subscription;
  public tiposLlamada = JSON.parse(this.tokenService.getParametros())["Calls"]["tipos"];
  public estadosLlamada = JSON.parse(this.tokenService.getParametros())["Calls"]["estados"];
  public canalContactosLlamada = JSON.parse(this.tokenService.getParametros())["Calls"]["canalContacto"];
  public estadosReunion = JSON.parse(this.tokenService.getParametros())["Meetings"]["estados"];
  public prioridadesTarea = JSON.parse(this.tokenService.getParametros())["Tasks"]["prioridades"];
  public estadosTarea = JSON.parse(this.tokenService.getParametros())["Tasks"]["estados"];
  public duraciones = JSON.parse(this.tokenService.getParametros())["Meetings"]["duracion"];
  public contactosSeleccionados;

  isRecording: boolean = false;
  play: boolean = false;

  tiempoTranscurrido: number = 0;
  tiempoLimite: number = 120; // 2 minutos en segundos
  intervalo: any;
  progress: number = 0;
  progressTools: number = 0;
  filename: string;
  textArea: any = "";




  constructor(
    public modalController: ModalController,
    public toastController: ToastController,
    private CrmService: CrmService,
    private tokenService: TokenService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private http: HttpClient

  ) {
  }


  ngOnInit() {

    VoiceRecorder.hasAudioRecordingPermission().then(result => {
      if (!result.value) {
        VoiceRecorder.requestAudioRecordingPermission();
      }
    })

    this.filename = `AudioApp${new Date().getTime()}.mp3`;

    if (this.reunion && this.reunion.duracion_horas) {
     
      this.totalSegundos = ((this.reunion.duracion_horas * 3600) + (this.reunion.duracion_minutos * 60)).toString();
      // this.validarReunion.value.duracionMinutos = totalSegundos + "";

      
    }
    this.validarReunion = this.fb.group({
      asuntos: new FormControl("", [Validators.required, Validators.minLength(10)]),
      fechasInicio: new FormControl("", []),
      lugar: new FormControl("", []),
      descripcion: new FormControl("", []),
      duracionMinutos: new FormControl(this.totalSegundos, [Validators.required]),

      // Estos campos son de editar reuniones
      contacto: new FormControl("", []),
      estado: new FormControl("Planned", [Validators.required, Validators.minLength(4)]),

    }

    );
    

    //Inicializa la fecha para el calendario con la fecha actual
    const arrAccionesNuevmp3tividad = [
      "registrarVisita",
      "crearReunion",
      "crearTarea",
    ];
    if (arrAccionesNuevmp3tividad.includes(this.accion)) {
      const date = new Date();
      const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm");
      const stringDate = formattedDate.toString();
      this.fechaInicioString = stringDate;
    }

    setTimeout(() => {
      let datePipe = new DatePipe("es-ES");
      this.ahora = datePipe.transform(new Date(), "yyyy-MM-dd");

      switch (this.accion) {
        case "editarLlamada":
          this.contactosActividad("Calls", this.llamada.id);
          break;
        case "editarReunion":
          const date = new Date(this.reunion.fecha_hora_inicio);
          const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm");
          const stringDate = formattedDate.toString();
          this.fechaInicioString = stringDate;

          // const fechmp3tual  = new Date(this.reunion.fecha_hora_inicio);
          // fechmp3tual.setHours(fechmp3tual.getHours() -5); //GMT -5
          // this.fechaInicioString = fechmp3tual.toISOString();
          this.contactosActividad("Meetings", this.reunion.id);
          break;

        case "editarTarea":
          const fechmp3tualTarea = new Date(this.tarea.fecha_hora_inicio);
          const formattedDateTarea = format(
            fechmp3tualTarea,
            "yyyy-MM-dd'T'HH:mm"
          );
          this.fechaInicioString = formattedDateTarea.toString();
          this.contactosActividad("Tasks", this.tarea.id);
          break;
        case "verReunion": //Caso cuando viene de Calendario
          let fechmp3tualVerReunion = new Date(this.reunion.fecha_hora_inicio);
          const formattedDateVerReunion = format(
            fechmp3tualVerReunion,
            "yyyy-MM-dd'T'HH:mm"
          );
          this.fechaInicioString = formattedDateVerReunion.toString();
          this.contactos = this.CrmService.consultarContactosPorRelacion(
            "Meetings",
            this.reunion.id
          );
          this.contactosActividad("Meetings", this.reunion.id);
          break;
      }
    }, 10);
    // // Mantiene inicializado los contactro
    if (this.contactos == undefined) {
      this.contactos = [];
    }
  }

  // Start transcribir Audio
  iniciarGrabacion() {
    this.filename = `AudioApp${new Date().getTime()}.mp3`;
    if (this.isRecording) {
      return;
    }
    this.isRecording = true;
    VoiceRecorder.startRecording();
    this.contadorTiempo();
  }

  contadorTiempo() {
    this.intervalo = setInterval(() => {
      this.tiempoTranscurrido++;
      this.mostrarProgreso();

      // Detener la grabación después de 2 minutos
      if (this.tiempoTranscurrido >= this.tiempoLimite) {
        clearInterval(this.intervalo);
        this.pararGrabacion();
      }
    }, 1000);
  }

  pararGrabacion() {
    clearInterval(this.intervalo); // Detener el contador de tiempo
    this.isRecording = false;
    VoiceRecorder.stopRecording()
      .then(result => {
        if (result && result.value) {
          var recordData = result.value.recordDataBase64;

          const base64Data = recordData; // Tu cadena en base64
          const byteCharacters = atob(base64Data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], {type: "audio/mp3"}); 
         

          // const audioBlobData = this.base64ToBlob(recordData, 'audio/mp3');
           this.sendAudioToServer(blob, this.filename);

          this.saveFile(recordData);
          
        } else {
          console.error("Error al detener la grabación: no se recibió ningún resultado válido.");
        }
        this.reiniciarContadorTiempo();
      })
  }

  reiniciarContadorTiempo() {
    this.tiempoTranscurrido = 0;
  }

  base64ToBlob(base64, contentType) {
    // Decodifica la cadena base64 y conviértela en una secuencia de bytes
    const byteCharacters = atob(base64);
    const byteArrays = [];

    // Divide la secuencia de bytes en trozos de tamaño más manejable
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      // Convierte estos bytes en un objeto Uint8Array y guarda en el array de bytes
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    // Crea el objeto Blob a partir del array de bytes
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }


  mostrarProgreso() {
    const minutos = Math.floor(this.tiempoTranscurrido / 60);
    const segundos = this.tiempoTranscurrido % 60;
    const tiempoFormateado = `${this.agregarCeros(minutos)}:${this.agregarCeros(segundos)}`;
    

    const minutosTranscurridos = this.tiempoTranscurrido / 60;
    this.progress = minutosTranscurridos / (this.tiempoLimite / 60);

    const progresoTiempo = this.progress;
    this.progressTools = Math.round(progresoTiempo * 100);

    if (this.progressTools > 100) {
      this.progressTools = 100;
    }
  }


  agregarCeros(valor: number): string {
    return valor < 10 ? '0' + valor : '' + valor;
  }

  async saveFile(recordData: string) {
   
    await Filesystem.writeFile({
      path: this.filename,
      directory: Directory.Data,
      data: recordData
    });

    
     this.transcribeAndDownloadSpeech();

  }

  async transcribeAndDownloadSpeech() {
    
    this.play = true;
    
    try {
      const base64Sound = await Filesystem.readFile({
        path: this.filename,
        directory: Directory.Data,
      });


     

      const mimeType = "audio/mp3";
      const audioRef = new Audio(`data:${mimeType};base64,${base64Sound.data}`);
      audioRef.oncanplaythrough = () => audioRef.play();
      audioRef.onended = () => {
        this.play = false;

      };
      audioRef.load();
      
      //Descomentar y funciona en Chrome
      // const audioBlobData = this.base64ToBlob(base64Sound.data, 'audio/mp3');
      // this.sendAudioToServer(audioBlobData, this.filename);

      



    } catch (error) {
      console.error('Error:', error);
    }
  }


  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private sendAudioToServer(audioBlob: Blob, filename: string) {
    this.CrmService.sendAudioToServer(audioBlob, filename).subscribe(response => {

      this.textArea = response['text'];
      this.reunion.descripcion = this.textArea;

      console.log(`Transcrinción:${this.validarReunion.value.descripcion}`);
    });
  }
  // Fin transcribir Audio

  segmentoTipoGestionChanged(e) {
    this.tipoGestion = e.detail.value;
  }

  buscarContactos(event: {
    component: IonicSelectableComponent; text: string;
  }) {
    let text = event.text.trim().toLowerCase();
    event.component.startSearch();

    if (!text) {
      // Close any running subscription.
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      event.component.items = [];
      event.component.endSearch();
      return;
    }

    this.subscription = this.CrmService.buscarContactos(text).subscribe(
      (response) => {
        // Subscription will be closed when unsubscribed manually.
        if (this.subscription.closed) {
          return;
        }
        this.contactos = [];
        for (const index in response["data"]) {
          var contacto = response["data"][index];

          this.contactos.push(
            new Contacto(
              contacto["id"],
              contacto["attributes"]["first_name"],
              contacto["attributes"]["last_name"],
              contacto["attributes"]["first_name"] +
              " " +
              contacto["attributes"]["last_name"],
              "", //contacto['attributes']['tipo_documento_c'],
              "", //contacto['attributes']['numero_documento_c'],
              contacto["attributes"]["title"],
              contacto["attributes"]["description"],
              contacto["attributes"]["primary_address_street"],
              contacto["attributes"]["primary_address_city"],
              contacto["attributes"]["primary_address_state"],
              contacto["attributes"]["primary_address_country"],
              contacto["attributes"]["phone_mobile"],
              contacto["attributes"]["phone_work"],
              contacto["attributes"]["email1"],
              moment(contacto["attributes"]["date_entered"]).toDate(),
              moment(contacto["attributes"]["date_modified"]).toDate()
            )
          );
        }
        event.component.items = this.contactos;
        event.component.endSearch();
      }
    );
  }

  async contactosActividad(tipoRegistro: string, idRegistro: string) {
    const response = await this.CrmService.consultarContactosActividad(
      tipoRegistro,
      idRegistro
    );

    for (var index in response["data"]) {
      this.cd.detectChanges();
      switch (this.actividad) {
        case "llamada":
          this.llamada.contactos += response["data"][index]["id"] + ",";
          this.contactosSeleccionados = this.llamada.contactos.split(",");
          break;
        case "reunion":
          setTimeout(() => {
            this.reunion.contactos = "";
          }, 0);

          this.reunion.contactos += response["data"][index]["id"] + ",";
          this.contactosSeleccionados = this.reunion.contactos.split(",");
          break;
        case "tarea":
          this.tarea.contactos += response["data"][index]["id"] + ",";
          this.contactosSeleccionados = this.tarea.contactos.split(",");

          break;
      }
      this.cd.detectChanges();
    }
  }

  crearLlamada(canalContacto) {
    var moment = require("moment");
    this.llamada.fecha_hora_inicio = moment().format("MM/DD/YYYY HH:mm:ss");
    this.llamada.tipo = "saliente";
    this.llamada.estado = "Held";
    this.llamada.canalContacto = canalContacto;
    this.CrmService.crearLlamada(this.llamada).subscribe(
      async (response) => {
        const toast = await this.toastController.create({
          color: "success",
          message: "Se ha creado la llamada.",
          duration: 2000,
        });
        this.cd.detectChanges();
        toast.present();

        this.llamada.id = response["data"]["id"];
        // this.llamadas.push(this.llamada);
        if (this.contacto.id != "") {
          this.CrmService.crearRelacion(
            "Calls",
            this.llamada.id,
            "Contact",
            this.contacto.id
          ).subscribe(
            async (response) => {
              const toast = await this.toastController.create({
                color: "success",
                message: "Se ha creado la relación llamada con contacto",
                duration: 2000,
              });
              toast.present();
            },
            async (err) => {
              console.log(err);
              const toast = await this.toastController.create({
                color: "danger",
                message: "Error... no se creó relación llamada con contacto",
                duration: 2000,
              });
              toast.present();
            }
          );
        }

        this.CrmService.agregarUsuarioActividad(
          "Calls",
          this.llamada.id
        ).subscribe(
          (response) => {
            console.log("Llamada agregada al calendario");
          },
          (err) => {
            console.log(err);
          }
        );
      },
      async (err) => {
        console.log(err);
        const toast = await this.toastController.create({
          color: "danger",
          message: "Error... no se creó la llamada",
          duration: 2000,
        });
        toast.present();
      }
    );
    this.cd.detectChanges();
    this.cerrarModal();
  }

  editarLlamada() {
    this.CrmService.editarLlamada(this.llamada).subscribe(
      async (response) => {
        const toast = await this.toastController.create({
          color: "success",
          message: "Se ha actualizado la llamada.",
          duration: 2000,
        });
        toast.present();
      },
      async (err) => {
        console.log(err);
        const toast = await this.toastController.create({
          color: "danger",
          message: "Error... no se actualizó la llamada",
          duration: 2000,
        });
        toast.present();
      }
    );

    this.cerrarModal();
  }

  async obtenerUbicaciones() {
    const coordinates = await Geolocation.getCurrentPosition();

    return coordinates;
  }

  convertirSegundos(duracionMinutos: any) {

    const minutosSegundo = duracionMinutos;
    if (minutosSegundo != "Ninguna") {
      console.log("evento", minutosSegundo);
      const minutos = Math.floor(minutosSegundo / 60);
      const horas = Math.floor(minutos / 60);


      this.reunion.duracion_horas = horas;
      this.reunion.duracion_minutos = minutos;


      return {
        horas: format(new Date().setHours(horas % 24, minutos % 60, minutosSegundo % 60), 'HH:mm:ss'),
        minutos: format(new Date().setHours(0, minutos % 60, minutosSegundo % 60), 'mm:ss')
      };
    }

  }



  async registrarVisita() {

    this.reunion.estado = "Held";
    this.reunion.fecha_hora_inicio = new Date();

    const coordinates = await this.obtenerUbicaciones();
    console.log("Latitude:", coordinates.coords.latitude);
    console.log("Longitude:", coordinates.coords.longitude);
    this.reunion.latitud = coordinates.coords.latitude;
    this.reunion.longitud = coordinates.coords.longitude;


    const date = parseISO(this.fechaInicioString);
    this.reunion.fecha_hora_inicio = date;

    this.CrmService.crearReunion(this.reunion).subscribe(
      async (response) => {
        const toast = await this.toastController.create({
          color: "success",
          message: "Se ha creado la reunión.",
          duration: 2000,
        });
        toast.present();

        this.reunion.id = response["data"]["id"];

        for (var index in this.contactosSeleccionados) {
          if (this.contactosSeleccionados[index] != "") {
            this.CrmService.crearRelacion(
              "Meetings",
              this.reunion.id,
              "Contact",
              this.contactosSeleccionados[index]
            ).subscribe(
              async (response) => {
                const toast = await this.toastController.create({
                  color: "success",
                  message: "Se ha creado la relación reunión con contacto",
                  duration: 2000,
                });
                toast.present();
              },
              async (err) => {
                console.log(err);
                const toast = await this.toastController.create({
                  color: "danger",
                  message: "Error... no se creó relación reunión con contacto",
                  duration: 2000,
                });
                toast.present();
              }
            );
          }
        }
        this.CrmService.agregarUsuarioActividad(
          "Meetings",
          this.reunion.id
        ).subscribe(
          (response) => {
            console.log("Reunión agregada al calendario");
          },
          (err) => {
            console.log(err);
          }
        );
      },
      async (err) => {
        console.log(err);
        const toast = await this.toastController.create({
          color: "danger",
          message: "Error... no se creó la reunión",
          duration: 2000,
        });
        toast.present();
      }
    );

    this.cerrarModal();
  }

  // async registrarVisita() {
  //   const coordinates = await this.obtenerUbicaciones();
  //   console.log("Latitude:", coordinates.coords.latitude);
  //   console.log("Longitude:", coordinates.coords.longitude);
  //   this.reunion.latitud = coordinates.coords.latitude;
  //   this.reunion.longitud = coordinates.coords.longitude;
  //   this.reunion.estado = "Held";
  //   this.reunion.fecha_hora_inicio = new Date();

  //   const segundosTotales = this.reunion.duracion_minutos;
  //   const tiempoConvertido = this.convertirSegundos(segundosTotales);



  //     // Ejemplo de uso
  //     console.log(`Horas: ${tiempoConvertido.horas}`); // Muestra las horas
  //     console.log(`Minutos: ${tiempoConvertido.minutos}`); // Muestra los minutos


  //   const date = parseISO(this.fechaInicioString);

  //   this.reunion.fecha_hora_inicio = date;

  //   this.CrmService.crearReunion(this.reunion).subscribe(
  //     async (response) => {
  //       const toast = await this.toastController.create({
  //         color: "success",
  //         message: "Se ha creado la reunión.",
  //         duration: 2000,
  //       });
  //       toast.present();

  //       this.reunion.id = response["data"]["id"];
  //       //this.reuniones.push(this.reunion);


  //       for (var index in this.contactosSeleccionados) {
  //         if (this.contactosSeleccionados[index] != "") {
  //           this.CrmService.crearRelacion(
  //             "Meetings",
  //             this.reunion.id,
  //             "Contact",
  //             this.contactosSeleccionados[index]
  //           ).subscribe(
  //             async (response) => {
  //               const toast = await this.toastController.create({
  //                 color: "success",
  //                 message: "Se ha creado la relación reunión con contacto",
  //                 duration: 2000,
  //               });
  //               toast.present();
  //             },
  //             async (err) => {
  //               console.log(err);
  //               const toast = await this.toastController.create({
  //                 color: "danger",
  //                 message: "Error... no se creó relación reunión con contacto",
  //                 duration: 2000,
  //               });
  //               toast.present();
  //             }
  //           );
  //         }
  //       }
  //       this.CrmService.agregarUsuarioActividad(
  //         "Meetings",
  //         this.reunion.id
  //       ).subscribe(
  //         (response) => {
  //           console.log("Reunión agregada al calendario");
  //         },
  //         (err) => {
  //           console.log(err);
  //         }
  //       );
  //     },
  //     async (err) => {
  //       console.log(err);
  //       const toast = await this.toastController.create({
  //         color: "danger",
  //         message: "Error... no se creó la reunión",
  //         duration: 2000,
  //       });
  //       toast.present();
  //     }
  //   );

  //   this.cerrarModal();
  // }

  crearReunion() {

    const date = new Date(this.fechaInicioString);
    this.reunion.fecha_hora_inicio = date;
    this.fechaInicioString = date.toISOString();

    this.CrmService.crearReunion(this.reunion).subscribe(
      async (response) => {
        const toast = await this.toastController.create({
          color: "success",
          message: "Se ha creado la reunión.",
          duration: 2000,
        });
        toast.present();

        this.reunion.id = response["data"]["id"];
        //this.reuniones.push(this.reunion);

        for (var index in this.contactosSeleccionados) {
          if (this.contactosSeleccionados[index] != "") {
            this.CrmService.crearRelacion(
              "Meetings",
              this.reunion.id,
              "Contact",
              this.contactosSeleccionados[index]
            ).subscribe(
              async (response) => {
                const toast = await this.toastController.create({
                  color: "success",
                  message: "Se ha creado la relación reunión con contacto",
                  duration: 2000,
                });
                toast.present();
              },
              async (err) => {
                console.log(err);
                const toast = await this.toastController.create({
                  color: "danger",
                  message: "Error... no se creó relación reunión con contacto",
                  duration: 2000,
                });
                toast.present();
              }
            );
          }
        }
        this.CrmService.agregarUsuarioActividad(
          "Meetings",
          this.reunion.id
        ).subscribe(
          (response) => {
            console.log("Reunión agregada al calendario");
          },
          (err) => {
            console.log(err);
          }
        );
      },
      async (err) => {
        console.log(err);
        const toast = await this.toastController.create({
          color: "danger",
          message: "Error... no se creó la reunión",
          duration: 2000,
        });
        toast.present();
      }
    );

    this.cerrarModal();
  }

  editarReunion() {

    this.CrmService.editarReunion(this.reunion).subscribe(
      async (response) => {
        const toast = await this.toastController.create({
          color: "success",
          message: "Se ha actualizado la reunión.",
          duration: 2000,
        });
        toast.present();
        for (var index in this.contactosSeleccionados) {
          if (this.contactosSeleccionados[index] != "") {
            this.CrmService.crearRelacion(
              "Meetings",
              this.reunion.id,
              "Contact",
              this.contactosSeleccionados[index]
            ).subscribe(
              async (response) => {
                const toast = await this.toastController.create({
                  color: "success",
                  message: "Se ha creado la relación reunión con contacto",
                  duration: 2000,
                });
                toast.present();
              },
              async (err) => {
                console.log(err);
                const toast = await this.toastController.create({
                  color: "danger",
                  message: "Error... no se creó relación reunión con contacto",
                  duration: 2000,
                });
                toast.present();
              }
            );
          }
        }
      },
      async (err) => {
        console.log(err);
        const toast = await this.toastController.create({
          color: "danger",
          message: "Error... no se actualizó la reunión",
          duration: 2000,
        });
        toast.present();
      }
    );
    this.cd.detectChanges();
    this.cerrarModal();
  }

  crearTarea() {
    this.tarea.estado = "Not Started";
    const date = parseISO(this.fechaInicioString);
    const fechaFormateada = format(date, "MM/dd/yyyy HH:mm:ss");

    this.tarea.fecha_hora_inicio = fechaFormateada;
    this.tarea.fecha_hora_vencimiento = fechaFormateada;

    this.CrmService.crearTarea(this.tarea).subscribe(
      async (response) => {
        const toast = await this.toastController.create({
          color: "success",
          message: "Se ha creado la tarea.",
          duration: 2000,
        });
        toast.present();

        this.tarea.id = response["data"]["id"];
        this.tareas = [];
        this.tareas.push(this.tarea);
      },
      async (err) => {
        console.log(err);
        const toast = await this.toastController.create({
          color: "danger",
          message: "Error... no se creó la tarea",
          duration: 2000,
        });
        toast.present();
      }
    );

    this.cerrarModal();
  }

  editarTarea() {
    this.CrmService.editarTarea(this.tarea).subscribe(
      async (response) => {
        const toast = await this.toastController.create({
          color: "success",
          message: "Se ha actualizado la tarea.",
          duration: 2000,
        });
        toast.present();
      },
      async (err) => {
        console.log(err);
        const toast = await this.toastController.create({
          color: "danger",
          message: "Error... no se actualizó la tarea",
          duration: 2000,
        });
        toast.present();
      }
    );

    this.cerrarModal();
  }

  crearTareaCliente() {
    this.CrmService.crearTarea(this.tarea).subscribe(
      async (response) => {
        const toast = await this.toastController.create({
          color: "success",
          message: "Se ha creado la tarea.",
          duration: 2000,
        });
        toast.present();

        this.tarea.id = response["data"]["id"];
        this.tareas.push(this.tarea);
      },
      async (err) => {
        console.log(err);
        const toast = await this.toastController.create({
          color: "danger",
          message: "Error... no se creó la tarea",
          duration: 2000,
        });
        toast.present();
      }
    );

    this.cerrarModal();
  }

  editarTareaCliente() {
    this.CrmService.editarTarea(this.tarea).subscribe(
      async (response) => {
        const toast = await this.toastController.create({
          color: "success",
          message: "Se ha actualizado la tarea.",
          duration: 2000,
        });
        toast.present();
      },
      async (err) => {
        console.log(err);
        const toast = await this.toastController.create({
          color: "danger",
          message: "Error... no se actualizó la tarea",
          duration: 2000,
        });
        toast.present();
      }
    );

    this.cerrarModal();
  }

  cerrarModal() {
    this.reiniciarContadorTiempo();
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  clienteChange(event: { component: IonicSelectableComponent; value: any }) {
    this.actualizarIdCliente(event.value.id);
    this.contactos = this.CrmService.consultarContactosPorRelacion(
      "Accounts",
      event.value.id
    );
  }

  contactoChange(event: { component: IonicSelectableComponent; value: any }) {
    this.actualizarContacto(event.value);
    this.cd.detectChanges();
  }

  actualizarAsunto(asunto: string) {
    switch (this.actividad) {
      case "llamada":
        this.llamada.asunto = asunto;
        break;
      case "reunion":
        this.reunion.asunto = asunto;
        break;
      case "tarea":
        this.tarea.asunto = asunto;
        break;
    }
  }

  actualizarEstado(estado: string) {
    switch (this.actividad) {
      case "llamada":
        this.llamada.estado = estado;
        break;
      case "reunion":
        this.reunion.estado = estado;
        if (estado == "Held") {
          console.log(estado);
          //TODO: Cambiar a capacitor geolocation
          // this.geolocation
          //   .getCurrentPosition()
          //   .then((resp) => {
          //     this.reunion.latitud = resp.coords.latitude;
          //     this.reunion.longitud = resp.coords.longitude;

          //     console.log(
          //       "Latitud: " +
          //         this.reunion.latitud +
          //         " Longitud: " +
          //         this.reunion.longitud
          //     );
          //   })
          //   .catch((error) => {
          //     console.log("Error obteniendo localización", error);
          //   });
        }
        break;
      case "tarea":
        this.tarea.estado = estado;
        break;
    }
    this.cd.detectChanges();
  }

  actualizarFechaInicio(ev: any) {
    let fechaInicio = ev.detail.value;
    const date = new Date(fechaInicio);
    const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm");
    const stringDate = formattedDate.toString();

    this.fechaInicioString = stringDate;
    // switch (this.actividad) {
    //   case "llamada":
    //     this.llamada.fecha_hora_inicio = fechaInicio;
    //     break;
    //   case "reunion":

    //     this.reunion.fecha_hora_inicio = fechaInicio;

    //     //this.reunion.fecha_hora_inicio = new Date(fechaInicio);
    //     //this.reunion.fecha_hora_inicio = fechaInicio;
    //     break;
    //   case "tarea":
    //     this.tarea.fecha_hora_inicio = fechaInicio;
    //     break;
    // }
    //this.cd.detectChanges();
  }

  // actualizarHorasDuracion(horasDuracion: number) {
  //   switch (this.actividad) {
  //     case "llamada":
  //       this.llamada.duracion_horas = horasDuracion;
  //       break;
  //     case "reunion":
  //       this.reunion.duracion_horas = horasDuracion;
  //       break;
  //   }
  // }

  actualizarMinutosDuracion(minutosDuracion: number) {
    switch (this.actividad) {
      case "llamada":
        this.llamada.duracion_minutos = minutosDuracion;
        break;
      case "reunion":
        this.reunion.duracion_minutos = minutosDuracion;

        break;
    }
  }

  actualizarContacto(contacto: string) {
    this.contactosSeleccionados = contacto;
    switch (this.actividad) {
      case "llamada":
        this.llamada.contactos = "" + this.contactosSeleccionados;
        break;
      case "reunion":
        this.reunion.contactos = "" + this.contactosSeleccionados;
        break;
      case "tarea":
        this.tarea.contactos = "" + this.contactosSeleccionados;
        break;
    }
  }

  actualizarDescripcion(descripcion: string) {
    switch (this.actividad) {
      case "llamada":
        this.llamada.descripcion = descripcion;
        break;
      case "reunion":
        this.reunion.descripcion = descripcion;
        break;
      case "tarea":
        this.tarea.descripcion = descripcion;
        break;
    }
  }

  actualizarLugar(lugar: string) {
    this.reunion.lugar = lugar;
  }

  actualizarFechaVencimiento(fechaVencimiento: Date) {
    this.tarea.fecha_hora_vencimiento = fechaVencimiento;
  }

  actualizarFechaRealizacion(ev: any) {
    let fechaRealizacion = ev.detail.value;
    this.fechaInicioString = fechaRealizacion;

    this.tarea.fecha_realizacion = fechaRealizacion;
    this.tarea.fecha_hora_inicio = fechaRealizacion;
    this.tarea.fecha_hora_vencimiento = fechaRealizacion;
    this.cd.detectChanges();
  }

  actualizarIdCliente(idCliente: string) {
    switch (this.actividad) {
      case "tarea":
        this.tarea.parent_id = idCliente;
        break;
      case "reunion":
        this.reunion.parent_id = idCliente;
        break;
    }
  }

  abrirWhatsapp() {
    window.open(
      "https://wa.me/" +
      this.contacto.celular +
      "?text=" +
      this.llamada.descripcion
    );

    this.cerrarModal();
  }

  abrirCorreo() {
    window.open(
      "mailto:" +
      this.contacto.email +
      "?subject=" +
      this.llamada.asunto +
      "&body=" +
      this.llamada.descripcion
    );
    this.cerrarModal();
  }

  abrirMensajeTexto() {
    window.open(
      "sms:" + this.contacto.celular + "?&body=" + this.llamada.descripcion
    );
    this.cerrarModal();
  }
}
