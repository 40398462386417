import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { AuthService } from './auth.service';
import { LoginUsuario } from './login-usuario';
import { TokenService } from './token.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {


  form: any = {};
  usuario: LoginUsuario;
  nombreUser: string;
  isLogged = false;
  isLoginFail = false;
  roles: string[] = [];
  errorMsg = '';

  showPassword = false;
  passwordToggleIcon ='eye';



  constructor(private authService: AuthService,
              private tokenService: TokenService,
              private alertController: AlertController,
              public router: Router) { }


togglePassword ():void {
  this.showPassword = !this.showPassword;

  if(this.passwordToggleIcon  == 'eye'){
    this.passwordToggleIcon = 'eye-off';

  }else{
    this.passwordToggleIcon = 'eye';
  }
}



  ngOnInit() {
    if (this.tokenService.getToken()) {
      this.nombreUser = this.tokenService.getUserName();
      this.isLogged = true;
      this.isLoginFail = false;
    }
  }

  onEnter() {
    this.onLogin(); 
  }

  getUsuario(){
    return this.usuario;
  }

  onLogin() {
    this.usuario = new LoginUsuario(this.form.email, this.form.password);
    
    this.authService.login(this.usuario)
      .subscribe(data => {
        //console.log(data);
        if(data.error != undefined){
          this.errorMsg = data.error;
          this.presentAlert();
          return;
        }   
        this.tokenService.setToken(data.access_token);
        this.tokenService.setRefreshToken(data.refresh_token);
        this.tokenService.setUserName(data.usuario);
        this.tokenService.setUri(data.base_uri);
        this.tokenService.setParametros(data.parametros);
        this.tokenService.setUserID(data.id_usuario);
        this.tokenService.setDBName(data.nombre_db);
        this.tokenService.setUserRol('');
        //this.tokenService.setAuthorities(data.authorities);
        this.isLogged = true;
        this.isLoginFail = false;
        //this.roles = this.tokenService.getAuthorities();
        this.cargarVariables();
        this.router.navigate(['/']);
      },
      (err: any) => {
        console.log(err);
        this.isLogged = false;
        this.isLoginFail = true;
        console.log(typeof(err.error.message));
        if(err.error.message.includes("The password is invalid") || err.error.message.includes("No user found")){
          this.errorMsg = "Usuario o contraseña inválida";
        }
        else {
          this.errorMsg = err.error.message;
        }
        this.presentAlert();
      }
    );
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'Falló la autenticación',
      message: this.errorMsg,
      buttons: ['Aceptar']
    });

    await alert.present();
  }
  
  cargarVariables() {
    window.localStorage.setItem('Clientes', '[]');
    window.localStorage.setItem('Contactos', '[]');
    window.localStorage.setItem('Negocios', '[]');
    window.localStorage.setItem('Productos', '[]');
    window.localStorage.setItem('Pedidos', '[]');
    window.localStorage.setItem('Reuniones', '[]');
    window.localStorage.setItem('Tareas', '[]');
    window.localStorage.setItem('Llamadas', '[]');
  }

  onLogout() {
    this.tokenService.logOut();
    window.location.reload();
  }
}
