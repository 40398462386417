<ion-header>
  <ion-toolbar >
    <ion-icon
    style="font-size: x-large"
      (click)="cerrarModal()"
      slot="end"
      color="primary"
      name="close-circle-outline"
    ></ion-icon>
    <ion-title *ngIf="accion == 'crearCliente'">Crear nuevo Cliente</ion-title>
    <ion-title *ngIf="accion == 'editarCliente'"
      >Editar {{ cliente.nombre }}</ion-title
    >
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="validarCliente" (ngSubmit)="onSubmit()">
    <ion-list>
      <ion-item>
        <ion-input
          label="Nombre:" labelPlacement="floating" placeholder="Escriba aquí...."
          formControlName="nombre"
          [value]="cliente.nombre"
          [(ngModel)]="cliente.nombre"
        ></ion-input>
      </ion-item>
      <ion-label
        color="danger"
        style="margin-left: 5%; text-align: right"
        *ngIf="
          validarCliente.controls.nombre.errors?.required &&
          (validarCliente.touched || validarCliente.dirty)
        "
      >
        * El campo es obligatorio
      </ion-label>

      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="validarCliente.controls.nombre.errors?.['minlength'] && (validarCliente.touched || validarCliente.dirty)"
        >* El nombre es muy corto.</ion-label
      >

      <ion-item>
        <ion-label position="floating"
          >Tipo de cliente:</ion-label
        >
        <ion-select
          formControlName="tipoCuenta"
          [(ngModel)]="cliente.tipo_cuenta"
          [value]="cliente.tipo_cuenta"
        >
          <ion-select-option
            *ngFor="let tiposCliente of tiposClientes"
            [value]="tiposCliente.valor"
            >{{ tiposCliente.etiqueta }}</ion-select-option
          >
        </ion-select>
      </ion-item>
      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="
          validarCliente.controls.tipoCuenta.errors?.required &&
          (validarCliente.touched || validarCliente.dirty)
        "
        >* Seleccione al menos una opción.</ion-label
      >

      <ion-item>
        <ion-input
          label="Teléfono:" labelPlacement="floating" placeholder="Escriba aquí...."
          type="tel"
          formControlName="telefono"
          [(ngModel)]="cliente.telefono"
          [value]="cliente.telefono"
        ></ion-input>
      </ion-item>
      <ion-label
        color="danger"
        style="margin-left: 5%; text-align: right"
        *ngIf="
          validarCliente.controls.telefono.errors?.required &&
          (validarCliente.touched || validarCliente.dirty)
        "
      >
        * El número es obligatorio
      </ion-label>
      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="validarCliente.controls.telefono.errors?.['minlength'] && (validarCliente.touched || validarCliente.dirty)"
      >
        * El número debe contener mínimo 10 caracteres.</ion-label
      >

      <ion-item>
        <ion-input
          label="Correo electrónico:" labelPlacement="floating" placeholder="Escriba aquí...."
          type="email"
          pattern="^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$"
          formControlName="email"
          [(ngModel)]="cliente.email"
          [value]="cliente.email"
        ></ion-input>
      </ion-item>
      <ion-label
        color="danger"
        style="margin-left: 5%; text-align: right"
        *ngIf="
          validarCliente.controls.email.errors?.required &&
          (validarCliente.touched || validarCliente.dirty)
        "
      >
        * El campo es obligatorio
      </ion-label>

      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="
          validarCliente.controls.email.errors?.email &&
          (validarCliente.touched || validarCliente.dirty)
        "
      >
        * El correo que ingresó no es válido.</ion-label
      >

      <ion-item>
         <ion-input
          label="Dirección:" labelPlacement="floating" placeholder="Escriba aquí...."
          formControlName="direccion"
          [(ngModel)]="cliente.direccion"
          [value]="cliente.direccion"
        ></ion-input>
      </ion-item>

      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="validarCliente.controls.direccion.errors?.['minlength'] && (validarCliente.touched || validarCliente.dirty)"
        >* Ingrese mínimo 6 caracteres.</ion-label
      >

      <ion-label
        color="danger"
        style="margin-left: 5%; text-align: right"
        *ngIf="
          validarCliente.controls.direccion.errors?.required &&
          (validarCliente.touched || validarCliente.dirty)
        "
      >
        * El campo es obligatorio
      </ion-label>

      <ion-item>
        <ion-input
          label="Ciudad:" labelPlacement="floating" placeholder="Escriba aquí...."
          formControlName="ciudad"
          [(ngModel)]="cliente.ciudad"
          [value]="cliente.ciudad"
        ></ion-input>
      </ion-item>
      <ion-label
        color="danger"
        style="margin-left: 5%; text-align: right"
        *ngIf="
          validarCliente.controls.ciudad.errors?.required &&
          (validarCliente.touched || validarCliente.dirty)
        "
      >
        * El campo es obligatorio
      </ion-label>
      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="validarCliente.controls.ciudad.errors?.['minlength'] && (validarCliente.touched || validarCliente.dirty)"
        >* Ingrese mínimo 4 caracteres.</ion-label
      >
      <ion-item position="stacked">
        <ion-input
        label="Departamento:" labelPlacement="floating" placeholder="Escriba aquí...."
          formControlName="departamento"
          [(ngModel)]="cliente.departamento"
          [value]="cliente.departamento"
        ></ion-input>
      </ion-item>
      <ion-label
        color="danger"
        style="margin-left: 5%; text-align: right"
        *ngIf="
          validarCliente.controls.departamento.errors?.required &&
          (validarCliente.touched || validarCliente.dirty)
        "
      >
        * El campo es obligatorio
      </ion-label>
      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="validarCliente.controls.departamento.errors?.['minlength'] && (validarCliente.touched || validarCliente.dirty)"
        >* Ingrese mínimo 4 caracteres.</ion-label
      >

      <ion-item position="stacked">
        <ion-input
        label="País:" labelPlacement="floating" placeholder="Escriba aquí...."
          formControlName="pais"
          [(ngModel)]="cliente.pais"
          [value]="cliente.pais"
        ></ion-input>
      </ion-item>
      <ion-label
        color="danger"
        style="margin-left: 5%; text-align: right"
        *ngIf="
          validarCliente.controls.pais.errors?.required &&
          (validarCliente.touched || validarCliente.dirty)
        "
      >
        * El campo es obligatorio
      </ion-label>
      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="validarCliente.controls.pais.errors?.['minlength'] && (validarCliente.touched || validarCliente.dirty)"
        >* Ingrese mínimo 3 caracteres.</ion-label
      >

      <ion-item>
        <ion-input
          label="Descripción:" labelPlacement="floating" placeholder="Escriba aquí...."
          formControlName="descripcion"
          [(ngModel)]="cliente.descripcion"
          [value]="cliente.descripcion"
        ></ion-input>
      </ion-item>
      <ion-label
        color="danger"
        style="margin-left: 5%; text-align: right"
        *ngIf="
          validarCliente.controls.descripcion.errors?.required &&
          (validarCliente.touched || validarCliente.dirty)
        "
      >
        * El campo es obligatorio
      </ion-label>
      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="validarCliente.controls.descripcion.errors?.['minlength'] && (validarCliente.touched || validarCliente.dirty)"
        >* Ingrese mínimo 10 caracteres.</ion-label
      >
      
    </ion-list>
    <ion-row style="height: 10px"></ion-row>
    <ion-button
      *ngIf="accion == 'crearCliente'"
      class="button"
      (click)="crearCliente()"
      [disabled]="validarCliente.invalid"
      >Guardar</ion-button
    >
    <ion-button
      *ngIf="accion == 'editarCliente'"
      class="button"
      (click)="editarCliente()"
      [disabled]="validarCliente.invalid"
      >Actualizar cliente</ion-button
    >
  </form>
</ion-content>
