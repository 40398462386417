import { NgxTimelineItemPosition } from '@frxjs/ngx-timeline';


export class Actividad {
    constructor(
        public id: string,
        public asunto: string, 
        public estado: string,
        public fecha_hora_inicio: Date, 
        public parent_type: string,
        public parent_name: string,
        public parent_id: string,
        public descripcion: string,
        public tipo_actividad: string,
        public assigned_user_name: string

    ) {}
}

