<ion-content class="background">
  <div align="center" *ngIf="!isLogged" style="margin-top: 5em">
    <img
      style="margin-top: 5%; width: 15em"
      src="../../assets/LogoTenebitCRMv2-1.png"
      alt
    />
    <ion-card style="margin-top: 5%">
      <ion-card-content>
        <form #login="ngForm">
          <ion-list>
            <ion-item>
              <ion-icon
                slot="start"
                name="mail-outline"
                style="position: inherit"
              ></ion-icon>
              <ion-input
                label="Correo Electrónico:"
                labelPlacement="floating"
                placeholder="Escriba aquí...."
                clear-input
                name="email"
                type="email"
                [(ngModel)]="form.email"
                pattern="^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$"
                required
              ></ion-input>
            </ion-item>
            <ion-item>
              <ion-icon slot="start" name="lock-closed-outline"></ion-icon>
              <ion-input
                label="Contraseña"
                labelPlacement="floating"
                placeholder="Escriba aquí...."
                clear-input
                name="password"
                [type]="showPassword ? 'text' : 'password'"
                [(ngModel)]="form.password"
                required
                (keyup.enter)="onEnter()"
              ></ion-input>
              <ion-icon
                slot="end"
                [name]="passwordToggleIcon"
                (click)="togglePassword()"
              ></ion-icon>
            </ion-item>
          </ion-list>
          <div class="ion-padding">
            <ion-button
              color="primary"
              expand="block"
              type="submit"
              [disabled]="!login.form.valid"
              (click)="onLogin()"
            >
              Iniciar sesión
            </ion-button>
          </div>
        </form>
      </ion-card-content>
    </ion-card>
  </div>
  <div *ngIf="isLogged">
    <ion-text color="primary" text-center>
      <h1>Bienvenido <b>{{ nombreUser }}</b></h1>
    </ion-text>
    <br />

    <ion-button expand="full" (click)="onLogout()" color="tertiary"
      >Cerrar Sesión</ion-button
    >
  </div>
  <div align="center"><ion-text color="medium">v 1.6</ion-text></div>
  <div
    style="
      text-align: center;
      margin-top: 2em;
      color: rgb(59, 53, 53);
      font-weight: bolder;
    "
  >
    <div><span> ¿Olvidaste tu contraseña?</span></div>
    <div style="margin-top: 2%; font-size: 13px">
      <a
        fill="clear"
        href="https://wa.me/+5746052060?text=Hola,%20olvide%20mi%20contraseña"
        >Solícita restablecer el acceso aquí</a
      >
    </div>
  </div>
</ion-content>
