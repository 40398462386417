<ion-header>
  <ion-toolbar>
    <ion-icon
      style="font-size: x-large"
      slot="end"
      color="primary"
      (click)="cerrarModal()"
      name="close-circle-outline"
    ></ion-icon>
    <ion-title
      *ngIf="accion == 'crearNegocio' || accion == 'crearNegocioCliente'"
      >Nuevo negocio</ion-title
    >
    <ion-title
      *ngIf="accion == 'editarNegocio' || accion == 'editarNegocioCliente'"
      >Actualizar negocio</ion-title
    >
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="validarNegocio">
    <ion-list>
      <ion-item>
        <ion-input
          label="Nombre:"
          labelPlacement="floating"
          placeholder="Escriba aquí"
          [(ngModel)]="negocio.nombre"
          [value]="negocio.nombre"
          formControlName="nombre"
        ></ion-input>
      </ion-item>
      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="
          validarNegocio.controls.nombre.errors?.required &&
          (validarNegocio.touched || validarNegocio.dirty)
        "
      >
        * El campo es obligatorio
      </ion-label>
      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="validarNegocio.controls.nombre.errors?.['minlength'] && (validarNegocio.touched || validarNegocio.dirty)"
        >* El nombre no está diligenciado o es muy corto.</ion-label
      >

      <ion-accordion-group>
        <ion-accordion value="start">
          <ion-item slot="header">
            <ion-label position="stacked"
              >Fecha estimada de Cierre<ion-text color="danger">
                *</ion-text
              ></ion-label
            >

            <ion-input
              formControlName="fechaCierre"
              slot="end"
              [(ngModel)]="fechaCierreString"
              [readonly]="true"
            ></ion-input>
          </ion-item>

          <ion-datetime
            locale="es-ES"
            [value]="fechaCierreString"
            (ionChange)="actualizarFechaCierreEvento($event)"
            slot="content"
            size="cover"
            [min]="ahora"
          >
            <span slot="time-label">Hora</span>
          </ion-datetime>
        </ion-accordion>
      </ion-accordion-group>

      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="
          validarNegocio.controls.fechaCierre.errors?.required &&
          (validarNegocio.touched || validarNegocio.dirty)
        "
      >
        * Seleccione la fecha
      </ion-label>

      <ion-item>
        <ion-input
          label="Monto:"
          labelPlacement="floating"
          placeholder="Escriba aquí"
          type="text"
          inputmode="numeric"
          pattern="^-?\d*$"
          [(ngModel)]="negocio.monto"
          [value]="negocio.monto"
          formControlName="montos"
        ></ion-input>
      </ion-item>

      <ion-label
        color="danger"
        style="margin-left: 5%; text-align: right"
        *ngIf="
          validarNegocio.controls.montos.errors?.required &&
          (validarNegocio.touched || validarNegocio.dirty)
        "
      >
        * El número es obligatorio
      </ion-label>
      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="validarNegocio.controls.montos.errors?.['minlength'] && (validarNegocio.touched || validarNegocio.dirty)"
      >
        * Agregue el monto de la propuesta, ejemplo: 100000.</ion-label
      >

      <ion-item>
        <ion-select
          label="Toma de contacto:"
          labelPlacement="stacked"
          [(ngModel)]="negocio.toma_contacto"
          [value]="negocio.toma_contacto"
          formControlName="tomaContacto"
        >
          <ion-select [value]=""></ion-select>
          <ion-select-option
            *ngFor="let tomaContacto of tomasContacto"
            [value]="tomaContacto.valor"
            >{{ tomaContacto.etiqueta }}</ion-select-option
          >
        </ion-select>
      </ion-item>
      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="
          validarNegocio.controls.tomaContacto.errors?.required &&
          (validarNegocio.touched || validarNegocio.dirty)
        "
      >
        * Seleccione un opción
      </ion-label>

      <ion-item>
        <ion-select
        label="Etapa de venta:"
        labelPlacement="stacked"
          formControlName="newEtapaVentas"
          [(ngModel)]="negocio.etapa_venta"
          [value]="negocio.etapa_venta"
        >
          <ion-select-option
            *ngFor="let etapaVenta of etapasVenta"
            [value]="etapaVenta.valor"
            >{{ etapaVenta.etiqueta }}</ion-select-option
          >
        </ion-select>
      </ion-item>
      <ion-label
        style="margin-left: 5%; text-align: right"
        color="danger"
        *ngIf="
          validarNegocio.controls.newEtapaVentas.errors?.required &&
          (validarNegocio.touched || validarNegocio.dirty)
        "
      >
        * Seleccione un opción
      </ion-label>

      <ion-item>

        <ion-input           label="Descripción:"
        labelPlacement="floating"
        placeholder="Escriba aquí" #descripcion [value]="negocio.descripcion"></ion-input>
      </ion-item>
    </ion-list>

    <ion-row style="height: 10px"></ion-row>
    <ion-button
      *ngIf="accion == 'crearNegocio'"
      class="button"
      (click)="crearNegocio()"
      [disabled]="validarNegocio.invalid"
      >Guardar</ion-button
    >
    <ion-button
      *ngIf="accion == 'editarNegocio'"
      class="button"
      (click)="editarNegocio()"
      [disabled]="validarNegocio.invalid"
      >Actualizar negocio</ion-button
    >
    <ion-button
      *ngIf="accion == 'crearNegocioCliente'"
      class="button"
      (click)="crearNegocioCliente()"
      [disabled]="validarNegocio.invalid"
      >Guardar</ion-button
    >
    <ion-button
      *ngIf="accion == 'editarNegocioCliente'"
      class="button"
      (click)="editarNegocioCliente()"
      [disabled]="validarNegocio.invalid"
      >Actualizar negocio</ion-button
    >
  </form>
</ion-content>
